import React, { FC, useState } from "react";
import { useForm, Controller, Resolver } from "react-hook-form";
import { IOtp, IVerifyOtpProps } from "../Auth.interface";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { LOCAL_STORAGE_KEYS, errorCode } from "src/constants/common";
import { setIsShowLoginModal, userLogin, userPhoneEmailLogin } from "src/redux/reducers/Login.slice";
import { useAppDispatch } from "src/redux/hooks";
import OTPField from "src/components/OTPField/OTPField";
import CustomButton from "src/components/CustomButton";
import { IInitAction } from "../Auth.interface";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { me } from "src/redux/reducers/common/Common.slice";
import { ChevronLeft } from "@untitled-ui/icons-react/build/cjs";
const VerifyOtp: FC<IVerifyOtpProps> = ({ authData, handleVerifyOtpRedirect, handleAuthAction, setAuthData }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handlerLoginOtpAgainSend = async () => {
        reset();
        let cread;
        if (authData.phone && authData.email) {
            cread = {
                email: authData.email,
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
            };
            const result: any = await dispatch(userPhoneEmailLogin(cread));
            if (result.type === userPhoneEmailLogin.fulfilled.toString()) {
            }
        } else {
            if (authData.isEmail) {
                cread = {
                    email: authData.email,
                };
            } else {
                cread = {
                    phone: `+${authData.phone.code}${authData.phone.number}`,
                    phone_country_code: authData.phone.country,
                };
            }
            const result: any = await dispatch(userLogin(cread));
            if (result.type === userLogin.fulfilled.toString()) {
            }
        }
    };
    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit(handleSave)();
        }
    };
    const schema = Yup.object({
        otp: Yup.string().length(4, "otp must be exactly 4 number").required("This field is a required "),
    }).required();
    const { handleSubmit, control, setError, reset, watch } = useForm<IOtp>({
        resolver: yupResolver(schema) as Resolver<IOtp>,
        defaultValues: {
            otp: "",
        },
    });

    const otpWatch = watch("otp");
    const handleClose = () => {
        dispatch(setIsShowLoginModal(false));
    };
    const handleSave = async (data: any) => {
        setLoading(true);
        let payload;
        if (authData.isEmail) {
            payload = {
                email: authData.email,
                otp: data.otp,
            };
        } else {
            payload = {
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
                otp: data.otp,
            };
        }
        if (authData.email && authData.phone) {
            axiosPost(API.USER.PHONE_EMAIL_VERIFY_OTP, payload)
                .then(async (response: any) => {
                    localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, response.data.data.token);
                    handleClose();
                    await dispatch(me());
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        Object.keys(response.data).forEach((field) => {
                            setError("otp", {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        // setValue('number', new Array(4).fill(''));
                    }
                })
                .finally(() => setLoading(false));
        } else {
            axiosPost(API.USER.VERIFY_OTP, payload)
                .then(async (result: any) => {
                    const enhancedAuthData = {
                        ...authData,
                        otp: data.otp,
                    };
                    if (result?.data?.data?.is_new_user) {
                        setAuthData(enhancedAuthData);
                        handleVerifyOtpRedirect();
                    } else {
                        if (result?.data?.data?.token && result?.data?.data?.is_password_set === false) {
                            setAuthData(enhancedAuthData);
                            localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, result.data.data.token);
                            handleClose();
                            await dispatch(me());
                        } else if (result?.data?.data?.token) {
                            setAuthData(enhancedAuthData);
                            localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, result.data.data.token);
                            handleClose();
                            await dispatch(me());
                        } else {
                            const nameAuthData = {
                                ...enhancedAuthData,
                                name: result?.data?.data?.first_name,
                            };
                            setAuthData(nameAuthData);
                            handleAuthAction("passwordVerification");
                        }
                    }
                })
                .catch((error) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        Object.keys(response.data).forEach((field) => {
                            setError("otp", {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        // setValue('number', new Array(4).fill(''));
                        // setactiveOTPIndex(0);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const handleAction = (type: keyof IInitAction) => () => {
        handleAuthAction(type);
    };

    const handleChangePhoneEmail = async () => {
        if (authData.isEmail) {
            setAuthData({
                ...authData,
                email: null,
                isEmail: false,
            });
        } else {
            setAuthData({
                ...authData,
                phone: null,
                isEmail: true,
            });
        }
        handleAuthAction("phoneEmailVerification");
    };

    return (
        <div className="font-primary model-inner-wrapper" onKeyDown={handleKeyPress}>
            <h2 className="login-model-title">{authData.isEmail ? t("Verify your email address") : t("Verify your phone number")}</h2>
            <div className="flex flex-col">
                <span className="login-model-subtitle"> {t("A 4-digit code has been sent to you at")}</span>
                <span className="login-model-subtitle-dec">{authData.isEmail ? authData.email : authData.phone.fullFormatedPhoneNumber}</span>
            </div>

            <div className="mx-auto mt-[30px]">
                <form onSubmit={handleSubmit(handleSave)}>
                    <Controller
                        name="otp"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col">
                                <OTPField otp={value} setOtp={onChange} errors={error} />
                                {/* {error && <p className="text-error text-center">{error.message}</p>} */}
                            </div>
                        )}
                    />
                    {authData.isEmail && <div className="text-textGrey text-center text-sm font-normal mt-1.5 -tracking-[0.02rem]">{t("Tip: Be sure to check your inbox and spam folders")}</div>}
                    <div className="mt-[24px] flex flex-col items-center">
                        <div className="flex items-center justify-center gap-1">
                            <p className="text-textGrey text-sm font-normal -tracking-[0.02rem] leading-[19.6px]">{t("Didn’t receive code")}? </p>
                            <CustomButton
                                type="button"
                                disabled={loading}
                                onClick={handlerLoginOtpAgainSend}
                                className="text-primary !border-0 !p-0 !h-auto leading-[19.6px] !font-semibold !bg-transparent text-sm text-center flex flex-col ring-0"
                                shadow={false}
                            >
                                {t(" Resend Code")}
                            </CustomButton>
                        </div>
                        {authData.email && authData.phone && (
                            <CustomButton
                                type="button"
                                className="!border-0 !bg-transparent text-center ring-0 leading-[19.6px] mt-[24px] !p-0 text-sm  text-primary font-bold"
                                shadow={false}
                                onClick={handleChangePhoneEmail}
                            >
                                {authData.isEmail ? t("Changed your email?") : t("Changed your mobile number?")}
                            </CustomButton>
                        )}
                    </div>
                    <div className="mt-[52px] w-full bottom-0 flex flex-col">
                        <CustomButton
                            type="button"
                            icon={<ChevronLeft width={20} />}
                            className="!border-0 ring-0 !p-0 !w-[32px] !h-[32px] absolute -left-[11px] lg:left-[12px] -top-[18px] lg:top-[12px] hover:!bg-gray-100 !rounded-lg  !bg-transparent text-center text-textGrey hover:!text-txtcolor"
                            shadow={false}
                            onClick={authData.email && authData.phone ? handleChangePhoneEmail : handleAction("login")}
                        ></CustomButton>
                        <CustomButton type="submit" className="w-full !h-[48px]" primary disabled={loading || (otpWatch && otpWatch.length !== 4 ? true : false)} isLoading={loading}>
                            <span> {t("Continue")}</span>
                        </CustomButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VerifyOtp;
