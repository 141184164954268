import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
    getBookingInfo,
    setBookingInitialState,
    setBookingTotal,
    setIsShowNoShowPolicy,
    setIsShowOrderSummary,
    setIsValidPaymentType,
    setPreview,
    setSelectedBookingDate,
    setSelectedBookingTime,
    setSelectedServices,
    setSelectedSpecialist,
    setStep,
    setSubmitTriggered,
    setTempStore,
} from "../Booking.slice";
import { BookingTABS, EBookingTabState } from "../bookAppointment.interface";
import { axiosPatch, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { ISpecialistService } from "src/Interface";
import { toast } from "react-toastify";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import { useNavigate } from "react-router-dom";
import { allShopProducts, currentShop, currentUser, me } from "src/redux/reducers/common/Common.slice";
import { PATH } from "src/constants/path";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import { calculateBookingTotal } from "src/utils/global-functions";
import { useBookingConditionsStatus } from "src/hooks/useBookingConditionsStatus";

interface IManageSubmit {
    isModal?: boolean;
}

const ManageSubmit: FC<IManageSubmit> = ({ isModal = false }) => {
    const { t } = useTranslation();
    const bookingInfo = useAppSelector(getBookingInfo);
    const productList = useAppSelector(allShopProducts);
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const { validateConditions } = useBookingConditionsStatus();

    useEffect(() => {
        const total = calculateBookingTotal(bookingInfo.selectedServices, bookingInfo.selectedProducts, bookingInfo);
        dispatch(setBookingTotal(total));
    }, [bookingInfo.selectedSpecialist, bookingInfo.selectedServices, bookingInfo.selectedBookingDate, bookingInfo.selectedBookingTime, bookingInfo.selectedProducts]);

    useEffect(() => {
        if (
            validateConditions(
                bookingInfo.step === EBookingTabState.SELECT_SERVICE,
                !!bookingInfo.selectedSpecialist,
                !!bookingInfo.selectedBookingDate,
                !!bookingInfo.selectedBookingTime,
                bookingInfo.tempStore.selectedServices.length > 0,
            )
        ) {
            const finalTotal = calculateBookingTotal(bookingInfo.tempStore.selectedServices, bookingInfo.selectedProducts, bookingInfo);
            dispatch(setBookingTotal(finalTotal));
        }
    }, [
        bookingInfo.selectedSpecialist,
        bookingInfo.selectedServices,
        bookingInfo.selectedBookingDate,
        bookingInfo.selectedBookingTime,
        bookingInfo.selectedProducts,
        bookingInfo.tempStore.selectedServices,
    ]);

    const getButtonLabel = () => {
        if (isModal) {
            return t("I Agree");
        }

        const hasSelectedAllRequiredFields = [
            bookingInfo.selectedSpecialist,
            bookingInfo.selectedBookingDate,
            bookingInfo.selectedBookingTime,
            bookingInfo.selectedServices?.length,
            !(productList.length > 0),
            !shop.admin.is_verified_stripe_account && !(user && user.total_loyalty_amount >= bookingInfo.bookingTotal),
        ].every(Boolean);

        if (bookingInfo.isModifyingAppointment) {
            if (hasSelectedAllRequiredFields && bookingInfo.isShowNoShowPolicy) {
                return t("Complete");
            } else {
                return t("Modify");
            }
        }

        return hasSelectedAllRequiredFields && bookingInfo.isShowNoShowPolicy ? t("Complete") : t("Continue");
    };

    const manageBookingProcess = (finalTotal?: null | number) => {
        const checkValue = finalTotal ? user && user.total_loyalty_amount >= finalTotal : user && user.total_loyalty_amount >= bookingInfo.bookingTotal;
        if (bookingInfo.isModifyingAppointment && bookingInfo.oldPaymentOption === "pay_now") {
            updateBooking();
        } else if (productList.length > 0) {
            navigate(`${PATH.APPOINTEMENT}/${BookingTABS.PRODUCT_BOOKING}`);
        } else if (!user) {
            dispatch(setIsShowLoginModal(true));
        } else if (shop.admin.is_verified_stripe_account || checkValue) {
            navigate(`${PATH.APPOINTEMENT}/${BookingTABS.FINALIZE_BOOKING}`);
        } else if (bookingInfo.isModifyingAppointment) {
            if (!bookingInfo.isShowNoShowPolicy) {
                dispatch(setIsShowNoShowPolicy(true));
            } else {
                updateBooking();
            }
        } else {
            handleCreateBooking();
        }
    };

    const handleCreateBooking = async () => {
        if (uiState.isMobile) {
            if (!bookingInfo.isShowNoShowPolicy && !bookingInfo.isShowOrderSummary) {
                dispatch(setIsShowNoShowPolicy(true));
            } else if (bookingInfo.isShowNoShowPolicy) {
                dispatch(setIsShowOrderSummary(true));
                dispatch(setIsShowNoShowPolicy(false));
            } else if (bookingInfo.isShowOrderSummary) {
                createBooking();
            }
        } else {
            if (!bookingInfo.isShowNoShowPolicy) {
                dispatch(setIsShowNoShowPolicy(true));
            } else {
                createBooking();
            }
        }
    };

    const handleCardAdd = () => {
        dispatch(setSubmitTriggered(true));
    };

    const updateBooking = () => {
        setIsLoading(true);
        let url = API.BOOKING.UPDATE_PAY_NOW;

        const params = {
            id: bookingInfo.modifyingAppointmentId,
            shop_id: bookingInfo.selectedShopLocation?.shop_id,
            location_id: bookingInfo.selectedShopLocation?.id,
        };
        const payload: any = {
            booking_date: bookingInfo.selectedBookingDate,
            booking_time: bookingInfo.selectedBookingTime ? `${bookingInfo.selectedBookingTime}:00` : undefined,
        };

        if (bookingInfo.oldPaymentOption === "pay_later") {
            url = API.BOOKING.UPDATE_PAY_LATER;

            payload.payment_type = bookingInfo.paymentType;
            payload.payment_option = bookingInfo.paymentOption;

            const products = bookingInfo.selectedProducts.map((product: any) => ({
                id: product.selectedVariant.inventory.id,
                quantity: 1,
            }));
            payload.products = products;

            const services = bookingInfo.selectedServices.map((service: any) => ({
                id: service.id,
                quantity: service.quantity,
            }));
            payload.services = services;
        }

        axiosPatch(url, payload, params)
            .then(async (response) => {
                dispatch(setBookingInitialState());
                await dispatch(me());
                toast.success(response.data.message);
                navigateBasedOnShopLocations();
            })
            .catch((error) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => setIsLoading(false));
    };

    const createBooking = () => {
        setIsLoading(true);
        const products = bookingInfo.selectedProducts.map((product: any) => ({
            id: product.selectedVariant.inventory.id,
            quantity: 1,
        }));

        const services = bookingInfo.selectedServices.map((service: any) => ({
            id: service.id,
            quantity: service.quantity,
        }));

        const params = {
            shop_id: bookingInfo.selectedShopLocation?.shop_id,
            location_id: bookingInfo.selectedShopLocation?.id,
        };

        const payload = {
            staff_id: bookingInfo.selectedSpecialist?.id,
            services,
            products,
            payment_type: bookingInfo.paymentType,
            payment_option: bookingInfo.paymentOption,
            booking_date: bookingInfo.selectedBookingDate,
            booking_time: bookingInfo.selectedBookingTime ? `${bookingInfo.selectedBookingTime}:00` : undefined,
            shop_promotion_id: bookingInfo.selectedPromocode?.id ?? undefined,
        };
        axiosPost(API.BOOKING.CREATE, payload, params)
            .then(async () => {
                dispatch(setBookingInitialState());
                await dispatch(me());
                navigateBasedOnShopLocations();
            })
            .catch((error) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => setIsLoading(false));
    };

    const handleBack = (step: EBookingTabState) => () => {
        dispatch(setStep(EBookingTabState.TAB_MENU));
        navigate(`${PATH.APPOINTEMENT}`);
        switch (step) {
            case EBookingTabState.TAB_MENU:
                navigateBasedOnShopLocations();
                break;
            case EBookingTabState.SELECT_SERVICE:
                dispatch(setTempStore({ selectedServices: [] }));
                break;
            case EBookingTabState.SELECT_DATE_TIME:
                dispatch(setTempStore({ selectedBookingDate: undefined, selectedBookingTime: undefined, selectedBookingDateTime: undefined }));
                break;
            case EBookingTabState.SELECT_SPECIALIST:
                dispatch(setTempStore({ selectedSpecialist: undefined }));
                break;
            case EBookingTabState.FINALIZE_BOOKING:
                let navigateStep = BookingTABS.TAB_MENU;
                if (productList.length > 0) {
                    navigateStep = BookingTABS.PRODUCT_BOOKING;
                }
                navigate(`${PATH.APPOINTEMENT}/${navigateStep}`);
                break;
            default:
                break;
        }
    };

    const handleChange = (step: EBookingTabState) => () => {
        switch (step) {
            case EBookingTabState.SELECT_DATE_TIME:
                dispatch(setSelectedBookingDate(bookingInfo.tempStore?.selectedBookingDate));
                dispatch(setSelectedBookingTime(bookingInfo.tempStore?.selectedBookingTime));
                dispatch(setTempStore({ selectedBookingDateTime: undefined }));
                dispatch(setPreview({ key: "selectedBookingDateTime", value: bookingInfo.tempStore?.selectedBookingDateTime }));
                if (!bookingInfo.selectedSpecialist) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SPECIALIST}`);
                } else if (!bookingInfo.selectedServices.length) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SERVICE}`);
                } else {
                    manageBookingProcess();
                }
                break;
            case EBookingTabState.SELECT_SERVICE:
                dispatch(setTempStore({ selectedServices: [] }));
                dispatch(setSelectedServices(bookingInfo.tempStore?.selectedServices));
                dispatch(setPreview({ key: "selectedServices", value: bookingInfo.tempStore?.selectedServices }));
                if (!bookingInfo.selectedSpecialist) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SPECIALIST}`);
                } else if (!(bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime)) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_DATE_TIME}`);
                } else {
                    manageBookingProcess();
                }
                break;
            case EBookingTabState.SELECT_SPECIALIST:
                dispatch(setSelectedSpecialist(bookingInfo.tempStore?.selectedSpecialist));
                dispatch(setPreview({ key: "selectedSpecialist", value: bookingInfo.tempStore?.selectedSpecialist }));
                dispatch(setTempStore({ selectedSpecialist: undefined }));
                if (bookingInfo.selectedServices.length) {
                    setIsLoading(true);
                    const payload = {
                        staff_id: bookingInfo.tempStore?.selectedSpecialist?.id,
                        booking_date: bookingInfo.selectedBookingDate,
                        booking_time: bookingInfo.selectedBookingTime ? `${bookingInfo.selectedBookingTime}:00` : undefined,
                    };
                    const params = {
                        shop_id: bookingInfo.selectedShopLocation?.shop_id,
                        location_id: bookingInfo.selectedShopLocation?.id,
                    };
                    axiosPost(API.SERVICE.LIST, payload, params)
                        .then(async (response) => {
                            const services = response.data.data;
                            const selectedServices: ISpecialistService[] = [];
                            bookingInfo.selectedServices.forEach((selectedService: ISpecialistService) => {
                                const service = services.find((item: ISpecialistService) => item.id === selectedService.id);
                                if (service) {
                                    selectedServices.push({ ...selectedService, price: service.price });
                                }
                            });

                            dispatch(setSelectedServices(selectedServices));
                            const finalTotal = await calculateBookingTotal(selectedServices, bookingInfo.selectedProducts, bookingInfo);
                            dispatch(setBookingTotal(finalTotal));
                            if (!(selectedServices.length > 0)) {
                                navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SERVICE}`);
                            } else if (!(bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime)) {
                                navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_DATE_TIME}`);
                            } else {
                                manageBookingProcess(finalTotal);
                            }
                            dispatch(setPreview({ key: "selectedServices", value: selectedServices }));
                        })
                        .catch((error) => toast.error(error?.message ?? "Something went wrong, Please try again"))
                        .finally(() => setIsLoading(false));
                } else if (!bookingInfo.selectedServices.length) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SERVICE}`);
                }
                break;
            default:
                break;
        }
    };

    const handleContinue = (step: EBookingTabState) => () => {
        switch (step) {
            case EBookingTabState.TAB_MENU:
                if (bookingInfo.isModifyingAppointment && bookingInfo.oldPaymentOption === "pay_now") {
                    updateBooking();
                } else {
                    if (productList.length > 0) {
                        navigate(`${PATH.APPOINTEMENT}/${BookingTABS.PRODUCT_BOOKING}`);
                    } else if (!user) {
                        dispatch(setIsShowLoginModal(true));
                        return;
                    } else {
                        if (shop.admin.is_verified_stripe_account || (user && user.total_loyalty_amount >= bookingInfo.bookingTotal)) {
                            navigate(`${PATH.APPOINTEMENT}/${BookingTABS.FINALIZE_BOOKING}`);
                        } else {
                            if (bookingInfo.isModifyingAppointment) {
                                if (!bookingInfo.isShowNoShowPolicy) {
                                    dispatch(setIsShowNoShowPolicy(true));
                                } else {
                                    updateBooking();
                                }
                            } else {
                                handleCreateBooking();
                            }
                        }
                    }
                }
                break;
            case EBookingTabState.PRODUCT_BOOKING:
                if (!user) {
                    dispatch(setIsShowLoginModal(true));
                    return;
                } else {
                    if (shop.admin.is_verified_stripe_account || (user && user.total_loyalty_amount >= bookingInfo.bookingTotal)) {
                        navigate(`${PATH.APPOINTEMENT}/${BookingTABS.FINALIZE_BOOKING}`);
                    } else {
                        if (bookingInfo.isModifyingAppointment) {
                            if (!bookingInfo.isShowNoShowPolicy) {
                                dispatch(setIsShowNoShowPolicy(true));
                            } else {
                                updateBooking();
                            }
                        } else {
                            handleCreateBooking();
                        }
                    }
                }
                // handleStep(EBookingTabState.FINALIZE_BOOKING);
                break;
            case EBookingTabState.FINALIZE_BOOKING:
                if (!bookingInfo.paymentType) {
                    dispatch(setIsValidPaymentType(true));
                } else if (bookingInfo.isModifyingAppointment) {
                    if (!bookingInfo.isShowNoShowPolicy) {
                        dispatch(setIsShowNoShowPolicy(true));
                    } else {
                        updateBooking();
                    }
                } else {
                    handleCreateBooking();
                }
                break;
        }
    };

    // eslint-disable-next-line complexity
    const renderStepComponent = () => {
        const isTabMenuDisabled = !(
            bookingInfo.selectedShopLocation &&
            bookingInfo.selectedBookingDate &&
            bookingInfo.selectedBookingTime &&
            bookingInfo.selectedServices.length &&
            bookingInfo.selectedSpecialist
        );

        const isSpecialistDisabled =
            !validateConditions(!!bookingInfo.selectedShopLocation, !!bookingInfo.tempStore?.selectedSpecialist) &&
            !validateConditions(!!bookingInfo.selectedShopLocation, !!bookingInfo.selectedSpecialist);

        const isServiceDisabled =
            !validateConditions(!!bookingInfo.selectedShopLocation, bookingInfo.tempStore?.selectedServices?.length > 0) &&
            !validateConditions(!!bookingInfo.selectedShopLocation, bookingInfo.selectedServices?.length > 0);

        const isDateDisabled =
            !validateConditions(!!bookingInfo.selectedShopLocation, !!bookingInfo.tempStore?.selectedBookingDate, !!bookingInfo.tempStore?.selectedBookingTime) &&
            !validateConditions(!!bookingInfo.selectedShopLocation, !!bookingInfo.selectedBookingDate, !!bookingInfo.selectedBookingTime);

        const isProductDisabled = !validateConditions(
            !!bookingInfo.selectedShopLocation,
            !!bookingInfo.selectedBookingDate,
            !!bookingInfo.selectedBookingTime,
            bookingInfo.selectedServices.length > 0,
            !!bookingInfo.selectedSpecialist,
        );

        switch (bookingInfo.step) {
            case EBookingTabState.TAB_MENU:
                return (
                    <div className=" gap-2 absolute bottom-0 w-full bg-contentBackground lg:relative -tracking-[0.03rem] lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto pt-2.5">
                        {/*  mx-auto items-center  justify-center mt-2.5  lg:mb-0`} */}
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.TAB_MENU)}
                                disabled={isLoading}
                                className=" -tracking-[0.03rem] hover:border-primary  px-5 h-[36px] !w-full lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            primary
                            disabled={isTabMenuDisabled || isLoading}
                            isLoading={isLoading}
                            className={`${bookingInfo.isModifyingAppointment ? "w-full !mx-0" : "w-full"} -tracking-[0.03rem]  mx-auto items-center  justify-center  lg:mb-0`}
                            onClick={handleContinue(EBookingTabState.TAB_MENU)}
                        >
                            {getButtonLabel()}
                        </CustomButton>
                    </div>
                );
            case EBookingTabState.SELECT_DATE_TIME:
                return (
                    <div className="absolute bottom-0 w-full bg-contentBackground lg:relative lg:bg-transparent gap-2 flex lg:gap-3 lg:items-center lg:!mx-auto ">
                        {/* lg:w-[420px] 2xl:w-[420px] 3xl:w-[520px] mx-auto items-center  justify-center mt-2.5  lg:mb-0`} */}
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.SELECT_DATE_TIME)}
                                disabled={isLoading}
                                className="border hover:border-primary -tracking-[0.03rem]  px-5 mt-2.5 h-[36px] w-full  lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            className={`${bookingInfo.isModifyingAppointment ? "w-full !mx-0" : "w-full"} mx-auto -tracking-[0.03rem] items-center justify-center mt-2.5  lg:mb-0`}
                            primary
                            onClick={handleChange(EBookingTabState.SELECT_DATE_TIME)}
                            isLoading={isLoading}
                            disabled={isLoading || bookingInfo.isShowNoShowPolicy ? false : isDateDisabled}
                        >
                            {getButtonLabel()}
                        </CustomButton>
                    </div>
                );
            case EBookingTabState.SELECT_SERVICE:
                return (
                    <div className="absolute bottom-0 w-full bg-contentBackground lg:relative lg:bg-transparent gap-2 flex lg:gap-3 lg:items-center lg:!mx-auto ">
                        {/* lg:w-[420px] 2xl:w-[420px] 3xl:w-[520px] mx-auto items-center  justify-center mt-2.5  lg:mb-0`} */}
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.SELECT_SERVICE)}
                                disabled={isLoading}
                                className=" hover:border-primary -tracking-[0.03rem] hover:text-primary px-5 mt-2.5 h-[36px] w-full  lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            className={`${bookingInfo.isModifyingAppointment ? "w-full " : "w-full"}   mx-auto items-center -tracking-[0.03rem] justify-center mt-2.5  lg:mb-0`}
                            primary
                            isLoading={isLoading}
                            disabled={isLoading || bookingInfo.isShowNoShowPolicy ? false : isServiceDisabled}
                            onClick={handleChange(EBookingTabState.SELECT_SERVICE)}
                        >
                            {getButtonLabel()}
                        </CustomButton>
                    </div>
                );
            case EBookingTabState.SELECT_SPECIALIST:
                return (
                    <div className="absolute bottom-0 w-full bg-contentBackground lg:relative lg:bg-transparent gap-2 flex lg:gap-3 lg:items-center lg:!mx-auto ">
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.SELECT_SPECIALIST)}
                                disabled={isLoading}
                                className="hover:border-primary hover:text-primary -tracking-[0.03rem]  px-5 mt-2.5 h-[36px] w-full  lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            className="w-full lg:relative mx-auto  items-center -tracking-[0.03rem] justify-center mt-2.5  lg:mb-0"
                            primary
                            onClick={handleChange(EBookingTabState.SELECT_SPECIALIST)}
                            isLoading={isLoading}
                            disabled={isLoading || (bookingInfo.isShowNoShowPolicy ? false : isSpecialistDisabled)}
                        >
                            {getButtonLabel()}
                        </CustomButton>
                    </div>
                );

            case EBookingTabState.PRODUCT_BOOKING:
                return (
                    <div className="absolute bottom-0 w-full bg-contentBackground lg:relative lg:bg-transparent gap-2 flex lg:gap-3 lg:items-center lg:!mx-auto ">
                        {/* lg:w-[420px] 2xl:w-[420px] 3xl:w-[520px] mx-auto items-center  justify-center mt-2.5  lg:mb-0`} */}
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={() => navigate(PATH.APPOINTEMENT)}
                                disabled={isLoading}
                                className=" hover:border-primary -tracking-[0.03rem]  px-5 mt-2.5 h-[36px] w-full  lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            className={`${bookingInfo.isModifyingAppointment ? "w-full !mx-0" : "w-full "}  -tracking-[0.03rem] mx-auto items-center  justify-center mt-2.5  lg:mb-0`}
                            primary
                            disabled={isProductDisabled || isLoading}
                            isLoading={isLoading}
                            onClick={handleContinue(EBookingTabState.PRODUCT_BOOKING)}
                        >
                            {isModal
                                ? t("I Agree")
                                : bookingInfo.isModifyingAppointment
                                ? !shop.admin.is_verified_stripe_account && !(user && user.total_loyalty_amount >= bookingInfo.bookingTotal) && bookingInfo.isShowNoShowPolicy
                                    ? t("Complete")
                                    : t("Modify")
                                : !shop.admin.is_verified_stripe_account && !(user && user.total_loyalty_amount >= bookingInfo.bookingTotal) && bookingInfo.isShowNoShowPolicy
                                ? t("Complete")
                                : t("Continue")}
                        </CustomButton>
                    </div>
                );

            case EBookingTabState.FINALIZE_BOOKING:
                if (bookingInfo.selectedShopLocation && bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime && bookingInfo.selectedServices.length && bookingInfo.selectedSpecialist) {
                    return (
                        <div className="absolute bottom-0 w-full bg-contentBackground lg:relative lg:bg-transparent gap-2 flex lg:gap-3 lg:items-center lg:!mx-auto ">
                            {!isModal && (
                                <CustomButton
                                    secondary
                                    onClick={handleBack(EBookingTabState.FINALIZE_BOOKING)}
                                    disabled={isLoading || bookingInfo.isCardLoading}
                                    className=" hover:border-primary -tracking-[0.03rem]  px-5 mt-2.5 h-[36px] w-full  lg:mb-0"
                                >
                                    {t("Back")}
                                </CustomButton>
                            )}
                            {bookingInfo.isCardAdd || (user && user.total_loyalty_amount >= bookingInfo.bookingTotal) ? (
                                <CustomButton
                                    className={`w-full mx-auto items-center tracking-[0.03rem] justify-center mt-2.5 lg:mb-0 ${bookingInfo.isShowNoShowPolicy ? "!h-[48px]" : ""}`}
                                    primary
                                    onClick={handleContinue(EBookingTabState.FINALIZE_BOOKING)}
                                    disabled={isLoading}
                                    isLoading={isLoading}
                                >
                                    {isModal ? t("I Agree") : t("Complete")}
                                </CustomButton>
                            ) : (
                                <CustomButton
                                    className={`w-full mx-auto items-center tracking-[0.03rem] justify-center mt-2.5 lg:mb-0 ${bookingInfo.isShowNoShowPolicy ? "!h-[48px]" : ""}`}
                                    primary
                                    onClick={handleCardAdd}
                                    disabled={bookingInfo.isCardLoading}
                                    isLoading={bookingInfo.isCardLoading}
                                >
                                    {t("Continue")}
                                </CustomButton>
                            )}
                        </div>
                    );
                } else {
                    return <></>;
                }

            default:
                return <></>;
        }
    };

    return renderStepComponent();
};

export default ManageSubmit;
