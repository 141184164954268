import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { axiosGet, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { toast } from "react-toastify";
import { getCardTypeImage } from "src/utils/global-functions";
import Sidebar from "src/components/Sidebar";
import AddNewCardTabComponent from "../BookAppointment/FinalizeBooking/AddNewCardTabComponent";
import { useTranslation } from "react-i18next";

const PaymentMethod = () => {
    const { t } = useTranslation();
    const [stripeCards, setStripeCards] = useState([]);
    const [isStripeCardLoading, setIsStripeCardLoading] = useState(false);
    const [defaultCardId, setDefalutCardId] = useState<string | undefined>(undefined);
    const [isShowAddNewCardTab, setIsShowAddNewCardTab] = useState(false);
    const uiState = useAppSelector((data) => data.UiStates);

    useEffect(() => {
        if (!isShowAddNewCardTab) {
            listStripeCards();
        }
    }, [isShowAddNewCardTab]);

    const listStripeCards = () => {
        setIsStripeCardLoading(true);
        axiosGet(API.STRIPE.CARD_LIST)
            .then((response) => {
                setStripeCards(response.data.data.cards);
                setDefalutCardId(response.data.data.default_card_id);
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsStripeCardLoading(false);
            });
    };

    const handleDefault = (paymentMethodId: string) => () => {
        if (defaultCardId === paymentMethodId) {
            return;
        }
        setIsStripeCardLoading(true);
        axiosPost(API.STRIPE.CARD_DEFAULT, { payment_method_id: paymentMethodId })
            .then(() => {
                setDefalutCardId(paymentMethodId);
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsStripeCardLoading(false);
            });
    };

    return (
        <div className="flex flex-col">
            <span className="text-[20px] mb-1.5 leading-[28px] font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">{t("Payment method")}</span>
            <span className="font-normal text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">{t("Update your plan payment details")}</span>
            <div className="flex flex-col gap-4 mt-5">
                {stripeCards.length > 0 ? (
                    stripeCards.map((stripeCard: any) => (
                        <div
                            className="p-3 rounded-secondary border border-borderColorPrimary flex items-start"
                            // onClick={(e) => {
                            //     e.stopPropagation();
                            //     handlePaymentMethodChange("card", stripeCard.id);
                            // }}
                            key={stripeCard.id}
                        >
                            <img src={getCardTypeImage(stripeCard.card.brand)} alt="Person" className="mr-4 rounded-lg w-[40px] h-[40px] object-contain" />
                            <div>
                                <h4 className="text-sm font-semibold text-txtcolor">
                                    {stripeCard.card.brand} card ending in {stripeCard.card.last4}
                                </h4>
                                {stripeCard.id === defaultCardId ? (
                                    <span className="text-xs">{t("Default")}</span>
                                ) : (
                                    <span className="text-xs text-primary cursor-pointer" onClick={handleDefault(stripeCard.id)}>
                                        {t("Set as default")}
                                    </span>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="bg-contentBackground border border-borderColorPrimary py-3 md:py-3 xl:py-3 pl-3 pr-6 flex gap-5 items-center justify-between rounded-xl relative cursor-pointer mt-3 h-[66px]">
                        <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1">{isStripeCardLoading ? t("Loading") : t("Card no available")}</p>
                    </div>
                )}
                <div className=" flex  justify-end">
                    <span className="text-sm font-semibold text-primary cursor-pointer" onClick={() => setIsShowAddNewCardTab(true)}>
                        + {t("Add payment method")}
                    </span>
                </div>
            </div>
            <Sidebar
                isOpen={isShowAddNewCardTab}
                handleClose={() => setIsShowAddNewCardTab(false)}
                position={`${uiState.isMobile ? "bottom" : "right"}`}
                size={`${uiState.isMobile ? "w-full" : "w-[460px]"}`} // w-[460px]"
                customClass=""
                isBottomView={uiState.isMobile ? true : false}
                handleBack={() => setIsShowAddNewCardTab(false)}
                isBack={true}
            >
                {isShowAddNewCardTab && (
                    <AddNewCardTabComponent
                        closeAddNewCardTab={() => {
                            setIsShowAddNewCardTab(false);
                        }}
                    />
                )}
            </Sidebar>
        </div>
    );
};

export default PaymentMethod;
