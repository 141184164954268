import React, { useEffect, useState } from "react";
import { PlaceholderImgPNG } from "src/theme/Images";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { BiCheck, BiHelpCircle, BiXCircle } from "react-icons/bi";
import BookingView from "src/app/Profile/Booking/BookingView";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { useAppSelector } from "src/redux/hooks";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";
import Sidebar from "src/components/Sidebar";
import { IInitAction, initAction } from "./Location.interface";
import { currentShop } from "src/redux/reducers/common/Common.slice";

const Booking = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isRefresh, setIsRefresh] = useState(true);
    const uiState = useAppSelector((data) => data.UiStates);
    const [bookings, setBookings] = useState([]);
    const [booking, setBooking] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState<IInitAction>(initAction);

    useEffect(() => {
        if (isRefresh) {
            listBookings();
        }
    }, [isRefresh]);

    const listBookings = () => {
        setIsLoading(true);
        const payload = {
            type: "upcoming",
        };
        const params = { shop_id: shop.id };
        axiosPost(API.BOOKING.LIST, payload, params)
            .then((response) => {
                setBookings(response.data.data);
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsLoading(false);
                setIsRefresh(false);
            });
    };

    const handleAction = (type: keyof IInitAction, data?: any) => () => {
        if (type === "booking") {
            setBooking(data);
        }
        setAction(() => ({ ...initAction, [type]: true }));
    };

    const handleClose = () => {
        setAction(initAction);
    };

    return isLoading ? (
        <></>
    ) : (
        <div className="top-0  bottom-0  left-0 right-0 lg:pt-5 px-5 lg:pb-5 absolute  overflow-y-auto booking-container">
            {bookings.length > 0 && (
                <>
                    <div className="ml-auto flex flex-col  w-full max-w-[334px] z-10 relative gap-5">
                        {bookings.map((item: IAppointmentBooking) => (
                            <div
                                key={item.id}
                                className="bg-cardBackground rounded-secondary p-[12px] shadow-[0px_4px_70px_0px_rgba(0,0,0,0.10)] flex flex-wrap items-center justify-between cursor-pointer font-primary"
                                onClick={handleAction("booking", item)}
                            >
                                <div className="flex item-center gap-[15px] w-full">
                                    <div className="relative">
                                        <div className="w-[44px] ">
                                            <img
                                                src={item.location.location_image_url}
                                                alt=""
                                                onError={(event) => (event.currentTarget.src = PlaceholderImgPNG)}
                                                className=" w-full h-[44px] object-cover relative rounded-primary"
                                            />
                                        </div>
                                        <div className=" flex gap-[10px] flex-wrap items-center mt-2 absolute bottom-6 right-[-4px]">
                                            {item.status === "confirmed" && (
                                                <span className="bg-success rounded-full p-[1px]">
                                                    <BiCheck className="text-white w-4 h-4 " />
                                                </span>
                                            )}
                                            {item.status === "pending" && (
                                                <span className="bg-pending rounded-full p-[1px]">
                                                    <BiHelpCircle className="text-white w-4 h-4" />
                                                </span>
                                            )}
                                            {item.status === "declined" && (
                                                <span className="bg-danger rounded-full p-[1px]">
                                                    <BiXCircle className="text-white w-4 h-4" />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="cont flex-1 flex flex-col justify-center ">
                                        <div className="flex flex-row gap-2 ">
                                            <p className="text-textGrey text-xs font-medium pt-0.5 tracking-[0.03rem]">{t("Appointment")}</p>
                                            <p className="text-txtAppointmentColor text-xs font-medium pt-0.5 tracking-[0.03rem] ">#{item?.receipt_code}</p>
                                        </div>
                                        <p className="font-medium text-base text-txtAppointmentColor mt-1 tracking-[0.03rem]">{item?.staff?.full_name}</p>
                                        <p className="text-textGrey text-xs leading-[18px] font-normal mt-1 tracking-[0.03rem]">
                                            {moment(item.booking_date).format("dddd, DD MMM YYYY • ")}
                                            {format(new Date(`${item.booking_date} ${item.booking_start_time}`), "hh:mm-")}
                                            {format(new Date(`${item.booking_date} ${item.booking_end_time}`), "hh:mm a")}
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="flex gap-[15px] items-center justify-between md:justify-end w-full md:w-auto mt-2.5 md:mt-0">
                                    <ButtonCmp className="btn_secondary_black text-sm w-1/2 md:px-5 py-[10px] md:w-auto md:min-w-[150px]" onClick={() => handlerViewInMapClick(item)}>
                                        <div className="flex gap-[5px] items-center">
                                            {t("View in map")} <FiMapPin size={14} />
                                        </div>
                                    </ButtonCmp>
                                    <ButtonCmp className="btn_primary text-sm w-1/2 md:px-5 py-[10px] border border-primary md:w-auto md:min-w-[150px]" onClick={() => setIsShowAppointmentDetailsModal(true)}>
                                        <div className="flex gap-[5px] items-center">
                                            {t("View details")} <FiArrowRight size={16} />
                                        </div>
                                    </ButtonCmp>
                                </div> */}
                            </div>
                        ))}
                    </div>
                    <Sidebar isOpen={action.booking} handleClose={handleClose} size="w-[460px]" position={uiState.isMobile ? "bottom" : "right"} isBottomView={uiState.isMobile}>
                        {action.booking && booking && <BookingView booking={booking} handleClose={handleClose} setIsRefresh={setIsRefresh} />}
                    </Sidebar>
                </>
            )}
        </div>
    );
};

export default Booking;
