import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "src/components/CustomButton";
import { ROUTES } from "src/constants/routes";
import { ConfigationsImg } from "src/theme/Images";

const NotConfigured = () => {
    const navigate = useNavigate();
    return (
        <div className="w-full h-screen flex justify-center items-center flex-col bg-[#F8F8F8]">
            {/* <div className="text-3xl ">Page Not Found</div>
        <div className="text-lg">The requested page could not be found. Please check the URL and try again.</div> */}
            <div className="w-[90%] max-auto md:w-[500px] md:h-[500px] flex rounded-[32px] p-[32px] items-center justify-center bg-white shadow-[0px_5.33px_16px_0px_#928C971A]">
                <div className="flex flex-col">
                    <div className="w-full mb-[32px]">
                        <img src={ConfigationsImg} alt="" className="" />
                    </div>
                    <div className="flex flex-col mb-[32px]">
                        <h1 className="text-txtAppointmentColor leading-[28.8px] font-semibold text-[24px] tracking-[-0.04rem] mb-2">Configuration is not set</h1>
                        <span className="text-textGrey font-normal text-[16px] leading-[22.4px] tracking-[-0.01rem]">Please configure the landing page.</span>
                    </div>
                    <CustomButton primary className="w-[235px] mx-auto" onClick={() => navigate(ROUTES.BOOK_APPOINTMENT)}>
                        Book Now
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default NotConfigured;
