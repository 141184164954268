import React, { useEffect, useRef } from "react";
import Location from "./Location";
import About from "./About";
import Testimonial from "./Testimonial";
import Product from "./Product";
import Course from "./Course";
import Service from "./Service";
import Professional from "./Professional";
import { useAppSelector } from "../../../redux/hooks";
import Gallery from "./Gallery";
import { useDispatch } from "react-redux";
import { selectTab, setIsScrollable } from "../../../redux/reducers/Landing.slice";
import Layout from "./Layout";
import Footer from "./Footer";
import { ISelectedTab } from "../../../Interface";
import { getSlug } from "src/utils/global-functions";
import NotConfigured from "src/app/NotConfigured";
type SectionRefs = {
    [key in ISelectedTab]: React.RefObject<HTMLDivElement>;
};

const Template2 = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(selectTab("about"));
    }, []);

    const settings: any = useAppSelector((data) => data.Common.settings)?.find((setting: any) => setting.type === (getSlug().isPreview ? "template_preview" : "template"))?.value;

    const locationSetting = settings?.location;
    const aboutSetting = settings?.about;
    const academySetting = settings?.academy;
    const gallerySetting = settings?.gallery;
    const testimonialsSetting = settings?.testimonial;
    const productsSetting = settings?.product;
    const servicesSetting = settings?.service;
    const teamSetting = settings?.team;
    const footerSetting = settings?.footer;
    const homeSettings = settings?.hero;

    const activeSection = useAppSelector((state) => {
        const initSelectedTab: any = state.LandingStates.initSelectedTab;
        return Object.keys(initSelectedTab).find((key: any) => initSelectedTab[key]) as ISelectedTab | undefined;
    });
    const isScrollable = useAppSelector((data) => data.LandingStates.isScrollable);

    // Refs for all sections
    const sectionRefs: SectionRefs = {
        hero: useRef<HTMLDivElement>(null),
        about: useRef<HTMLDivElement>(null),
        location: useRef<HTMLDivElement>(null),
        team: useRef<HTMLDivElement>(null),
        service: useRef<HTMLDivElement>(null),
        academy: useRef<HTMLDivElement>(null),
        gallery: useRef<HTMLDivElement>(null),
        testimonial: useRef<HTMLDivElement>(null),
        product: useRef<HTMLDivElement>(null),
        language: useRef<HTMLDivElement>(null),
        footer: useRef<HTMLDivElement>(null),
    };
    // Utility function to debounce rapid function calls
    const debounce = (func: (...args: any[]) => void, wait: number): ((...args: any[]) => void) => {
        let timeout: number | undefined; // Use undefined as the initial state

        return function (...args: any[]): void {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = window.setTimeout(later, wait); // Use window.setTimeout for clarity in a browser environment
        };
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight / 4; // Adjust the divisor based on your needs
            let closestSection = null;
            let smallestDistance = Infinity;

            Object.entries(sectionRefs).forEach(([key, ref]) => {
                if (ref.current) {
                    const { offsetTop } = ref.current;
                    // Calculate distance from the section's top to the middle of the viewport
                    const distance = Math.abs(scrollPosition - offsetTop);
                    if (distance < smallestDistance) {
                        smallestDistance = distance;
                        closestSection = key;
                    }
                }
            });

            if (closestSection && closestSection !== activeSection) {
                dispatch(setIsScrollable(false));
                dispatch(selectTab(closestSection));
            }
        };

        // Debounce the scroll event handler to improve performance
        const debouncedHandleScroll = debounce(handleScroll, 100);

        window.addEventListener("scroll", debouncedHandleScroll);
        return () => window.removeEventListener("scroll", debouncedHandleScroll);
    }, [dispatch, activeSection, sectionRefs]);
    useEffect(() => {
        if (isScrollable && activeSection && sectionRefs[activeSection]?.current) {
            sectionRefs[activeSection]!.current!.scrollIntoView({ behavior: "smooth" });
        }
    }, [activeSection]);
    let sectionIndex = 1;
    const [activeSettings, setActiveSettings] = React.useState<string[]>([]);

    useEffect(() => {
        const extractKeysWithStatusTrue = (obj: any) => {
            const result = [];
            for (const key in obj) {
                if (obj[key].status === true) {
                    result.push(key);
                }
            }
            return result;
        };
        const filterData = extractKeysWithStatusTrue(settings);
        setActiveSettings(filterData);
    }, [settings]);

    return (
        <div>
            {settings?.length !== 0 && activeSettings.length !== 0 ? (
                <>
                    <Layout homeSettings={homeSettings}>
                        <div className="w-full mx-auto">
                            {aboutSetting?.status && (
                                <div ref={sectionRefs.about} className="main-section-spaching ">
                                    <About data={aboutSetting} sectionIndex={sectionIndex++} />
                                </div>
                            )}
                            {locationSetting?.status && (
                                <div ref={sectionRefs.location} className="main-section-spaching ">
                                    <Location sectionIndex={sectionIndex++} data={locationSetting} />
                                </div>
                            )}

                            {teamSetting?.status && (
                                <div ref={sectionRefs.team} className="main-section-spaching ">
                                    <Professional data={teamSetting} locationSetting={locationSetting} sectionIndex={sectionIndex++} />
                                </div>
                            )}

                            {servicesSetting?.status && (
                                <div ref={sectionRefs.service} className="main-section-spaching ">
                                    <Service data={servicesSetting} sectionIndex={sectionIndex++} />
                                </div>
                            )}

                            {academySetting?.status && (
                                <div ref={sectionRefs.academy} className="main-section-spaching ">
                                    <Course data={academySetting} sectionIndex={sectionIndex++} />
                                </div>
                            )}

                            {productsSetting?.status && (
                                <div ref={sectionRefs.product} className="main-section-spaching">
                                    <Product data={productsSetting} sectionIndex={sectionIndex++} />
                                </div>
                            )}

                            {testimonialsSetting?.status && (
                                <div ref={sectionRefs.testimonial} className="main-section-spaching mb-0">
                                    <Testimonial data={testimonialsSetting} sectionIndex={sectionIndex++} />
                                </div>
                            )}
                        </div>
                    </Layout>
                    {gallerySetting?.status && (
                        <div ref={sectionRefs.gallery} className="min-h-[100vh]">
                            <Gallery data={gallerySetting} />
                        </div>
                    )}
                    {footerSetting?.status && <Footer data={footerSetting} />}
                </>
            ) : (
                <NotConfigured />
            )}
        </div>
    );
};

export default Template2;
