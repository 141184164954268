import { Sidebar as PrimeSidebar } from "primereact/sidebar";
import React, { forwardRef, ReactNode, useEffect } from "react";
import { GoArrowLeft } from "react-icons/go";
import { logout } from "src/utils/global-functions";
import CustomButton from "../CustomButton";

interface IDrawerProps {
    children: ReactNode;
    isOpen: boolean;
    handleClose: () => void;
    handleBack?: () => void;
    isBack?: boolean;
    customClass?: string;
    size?: string;
    position?: "top" | "bottom" | "left" | "right" | undefined;
    isBottomView?: boolean;
    dismissable?: boolean;
    showCloseIcon?: boolean;
    isLogout?: boolean;
    ref?: any;
    primaryButton?: string;
    secondaryButton?: string;
    acceptAction?: () => void;
    declineAction?: () => void;
}

const Sidebar = forwardRef<HTMLDivElement, IDrawerProps>(
    (
        {
            children,
            isOpen,
            handleClose,
            customClass,
            size,
            position = "right",
            handleBack,
            isBack = false,
            isBottomView = false,
            dismissable = false,
            showCloseIcon = true,
            isLogout = false,
            primaryButton = "",
            secondaryButton = "",
            acceptAction = () => {},
            declineAction = () => {},
        },
        ref,
    ) => {
        useEffect(() => {
            if (isLogout) {
                const handleClickOutside = (event: any) => {
                    const element = ref && typeof ref !== "function" && ref.current;
                    if (element && !element.contains(event.target)) {
                        if (isLogout) {
                            logout();
                        }
                        handleClose();
                    }
                };
                document.addEventListener("mousedown", handleClickOutside);
                return () => {
                    document.removeEventListener("mousedown", handleClickOutside);
                };
            }
        }, [isLogout]);

        const onContinue = () => {
            console.log("helodfjsdkfsdf");
        };
        return (
            <PrimeSidebar
                visible={isOpen}
                onHide={handleClose}
                position={position}
                dismissable={dismissable}
                className={`${size} ${customClass} ${isBottomView && "w-full h-[92%] rounded-t-[24px]"} bg-contentBackground font-primary sidebar-wrapper relative`}
                showCloseIcon={showCloseIcon}
            >
                <div ref={ref} className="w-full flex flex-col flex-1 max-lg:relative">
                    {isBack && handleBack && (
                        <button
                            type="button"
                            className="w-[36px] h-[36px] bg-contentBackground border border-borderColorSecondary rounded-primary flex items-center justify-center absolute -left-[24px]"
                            onClick={handleBack}
                        >
                            <GoArrowLeft color="var(--brand-color)" />
                        </button>
                    )}
                    {children}
                    {(primaryButton || secondaryButton) && (
                        <div className="flex gap-3 absolute bottom-0 mb-3 bg-contentBackground w-full">
                            <CustomButton className="w-full" secondary onClick={declineAction}>
                                Cancel
                            </CustomButton>
                            <CustomButton primary className="w-full" onClick={acceptAction}>
                                Continue
                            </CustomButton>
                        </div>
                    )}
                </div>
            </PrimeSidebar>
        );
    },
);
export default Sidebar;
