import React, { FC, Fragment, useEffect, useState } from "react";
import { IShopLocationCategory, ISpecialistService } from "../../../Interface";
import { toast } from "react-toastify";
import { errorCode } from "../../../constants/common";
import { API } from "../../../constants/api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { axiosGet } from "../../../utils/requestClient";
import ServiceCardComponent from "./ServiceCardComponent";
import { getBookingInfo, setStep, setTempStore } from "../Booking.slice";
import BarLoader from "../../../components/BarLoader";
import { useTranslation } from "react-i18next";
import { EBookingTabState } from "../bookAppointment.interface";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";
import { Collapse } from "src/components/Collapse/Collapse";
import { Dropdown, Spinner } from "flowbite-react";
import { Check, FilterLines } from "@untitled-ui/icons-react/build/cjs";
import { useNavigate } from "react-router-dom";
import { ISelectServices, Item } from "./SelectServices.interface";
import { PATH } from "src/constants/path";
import Stepper from "../Stepper";

const SelectServices: FC<ISelectServices> = ({ services, getServices }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((data) => data.UiStates);
    const [isLoading, setIsLoading] = useState(true);
    const [filteredServices, setFilteredServices] = useState<ISpecialistService[]>([]);
    const [categoryLists, setCategoryLists] = useState<IShopLocationCategory[]>([]);
    const [isShopLocationServicesLoading, setIsShopLocationServicesLoading] = useState(false);
    const [isShopLocationCategoryLoading, setIsShopLocationCategoryLoading] = useState(false);
    const [categoryFilterValue, setCategoryFilterValue] = useState<any | null>(null);
    const [searchFilterValue, setSearchFilterValue] = useState<string>("");

    useEffect(() => {
        dispatch(setStep(EBookingTabState.SELECT_SERVICE));
        if (!services.length) {
            getServices();
        }
    }, []);

    useEffect(() => {
        const initialLoad = async () => {
            await dispatch(setTempStore({ selectedServices: bookingState.selectedServices }));
            await loadCategories();
            setIsLoading(false);
        };
        initialLoad();
    }, []);

    useEffect(() => {
        const filteredRecords = services.filter((serviceRecord: ISpecialistService) => {
            let searchMatched = false;
            let categoryMatched = false;

            // search value matching
            if (searchFilterValue !== "") {
                if (serviceRecord.name.toLocaleLowerCase().includes(searchFilterValue.toLocaleLowerCase())) {
                    searchMatched = true;
                }
            } else {
                searchMatched = true;
            }

            // category filtering
            if (categoryFilterValue && categoryFilterValue.value) {
                categoryMatched = serviceRecord.shop_category_id === categoryFilterValue.value;
            } else {
                categoryMatched = true;
            }
            return searchMatched && categoryMatched;
        });
        const groupByParent = groupByParentId(filteredRecords as unknown as Item[]);
        setFilteredServices(groupByParent as unknown as ISpecialistService[]);

        setFilteredServices(filteredRecords);
    }, [categoryFilterValue, searchFilterValue, services]);

    const groupByParentId = (data: Item[]): Item[] => {
        // Create a map to hold the grouped data
        const map: { [key: number]: Item } = {};

        // Loop through the data and initialize the map
        data.forEach((item) => {
            if (!map[item.id]) {
                map[item.id] = { ...item, children: [] };
            }
            if (item.parent_id) {
                if (!map[item.parent_id]) {
                    map[item.parent_id] = { children: [] } as unknown as Item;
                }
                map[item.parent_id].children!.push(map[item.id]);
            }
        });

        // Extract the grouped data into an array
        return Object.values(map).filter((item) => !item.parent_id);
    };

    const loadCategories = async () => {
        try {
            setIsShopLocationCategoryLoading(true);
            const payloadObj = {
                shop_id: bookingState.selectedShopLocation?.shop_id,
                location_id: bookingState.selectedShopLocation?.id,
            };
            const response = await axiosGet(API.CATEGORY.LIST, {}, payloadObj);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setCategoryLists(response.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message ?? "Something went wrong, Please try again");
        } finally {
            setIsShopLocationCategoryLoading(false);
        }
    };

    const handleClose = async () => {
        navigate(`${PATH.APPOINTEMENT}`);
        await dispatch(setStep(EBookingTabState.TAB_MENU));
        await dispatch(setTempStore({ selectedServices: [] }));
    };
    const handleRemoveService = async (id: any) => {
        const removeFromTemp = bookingState?.tempStore?.selectedServices?.filter((_, idx) => idx !== id);
        await dispatch(setTempStore({ selectedServices: removeFromTemp }));
    };
    return (
        <div className="h-full relative">
            <div className="flex flex-row w-full justify-between items-center px-2  mb-4">
                <div className="title text-txtcolor font-bold text-2xl leading-[128%]  md:mt-0 w-full">
                    <h3 className="location-title-text ">Select services</h3>
                    <p className="location-sub-text hidden lg:block">Please select services as per your requirement</p>
                </div>
                <div className="flex relative">
                    <Dropdown
                        className="bg-dropdownBackgroundColor border-borderColorPrimary border-0"
                        theme={{
                            arrowIcon: "ml-2 h-4 w-4",
                            content:
                                "py-1 focus:outline-none bg-dropdownBackgroundColor !right-0 fixed z-50 rounded-secondary shadow-InputAndButton px-2.5 py-2 space-y-1 border border-borderColorPrimary shadow-InputAndButton",
                        }}
                        label=""
                        placement="bottom-start"
                        dismissOnClick={true}
                        inline
                        renderTrigger={() => (
                            <button
                                type="button"
                                className="min-w-[36px] h-[36px] sm:min-w-[40px] sm:h-[40px] border border-borderColorSecondary lg:border-borderColorSecondary rounded-md flex items-center justify-center lg:mt-0 relative"
                            >
                                {/* <FilterFunnel02 className="w-[20px] h-[20px] lg:hidden text-textGrey" /> */}
                                <FilterLines color="#8C9CBB" className="" />
                                {/* <span className="hidden lg:block text-textGrey text-base font-normal">Filter</span> */}
                            </button>
                        )}
                    >
                        {categoryLists.map((categoryList) => (
                            <Dropdown.Item
                                theme={{
                                    container: "right-0",
                                    icon: "text-textGrey mr-3 h-4 w-4 bg-black ",
                                    base: `${
                                        categoryFilterValue?.value === categoryList.id && " bg-[#F8F8F8] rounded-[8px] text-txtcolor focus:outline-none"
                                    }  flex w-[250px] md:w-[210px]  right-0 -tracking-[0.02rem] cursor-pointer items-center justify-start px-2.5  py-2 text-sm font-normal text-txtcolor  active:text-txtcolor active:bg-[#F8F8F8] active:rounded-[8px] hover:rounded-[8px] focus:rounded-[8px] hover:bg-[#F8F8F8] focus:bg-[#F8F8F8] focus:outline-none focus:ring-0`,
                                }}
                                key={categoryList.id}
                                onClick={() => {
                                    categoryFilterValue?.value === categoryList.id ? setCategoryFilterValue(null) : setCategoryFilterValue({ label: categoryList.name, value: categoryList.id });
                                }}
                            >
                                <div className="flex items-center justify-between gap-2 w-full">
                                    {categoryList.name}
                                    {categoryFilterValue?.value === categoryList.id && <Check className="text-primary h-4 w-4 mr-1.5" />}
                                </div>
                            </Dropdown.Item>
                        ))}
                    </Dropdown>
                    {categoryFilterValue && <div className="w-[13px] h-[13px] bg-primary rounded-full absolute -top-1 -right-1 border-[3px]  border-contentBackground "></div>}
                </div>
            </div>
            <div className={`px-2  ${filteredServices.length <= 0 && !isShopLocationServicesLoading ? " " : "mb-[36px]"}`}>
                <Stepper />
            </div>
            {isShopLocationServicesLoading && (
                <div className="w-full md:w-1/2 mt-2 mb-2 px-2">
                    <BarLoader isLoading={isShopLocationServicesLoading} />
                </div>
            )}
            {/* <div className="mt-2 md:mt-0 w-full md:w-[320px] md:block">
                    <InputWithLabel
                        buttonIcon={searchFilterValue ? <IoCloseCircle className="text-secondaryTxt text-xl" /> : <IoSearchOutline color="#8C9CBB" size={18} className="mr-1" />}
                        handlerIconClick={() => setSearchFilterValue("")}
                        type="text"
                        inputClass="pl-[41px]"
                        placeholder={t("Search a services")}
                        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchFilterValue(e.target.value)}
                        value={searchFilterValue}
                        name="firstname"
                        disabled={isShopLocationServicesLoading}
                    />
                </div> */}
            {/* <div className="flex flex-wrap pb-5"></div> */}

            {/* {uiState.isMobile ? (
                <div>
                    <div className="-mx-[10px] h-[calc(100vh-250px)] lg:h-[60vh] overflow-scroll scrollbar-hide py-5 pb-14 2xl:pb-24 content-baseline">
                        {bookingState?.tempStore?.selectedServices?.map((serviceData: ISpecialistService) => (
                            <ServiceCardMobileComponent key={`selected-${serviceData.id}`} serviceData={serviceData} />
                        ))}
                        {filteredServices.map((serviceData: ISpecialistService) => (
                            <ServiceCardMobileComponent key={serviceData.id} serviceData={serviceData} />
                        ))}
                        {filteredServices.length <= 0 && !isShopLocationServicesLoading && (
                            <div className=" justify-center flex w-full h-full items-center">
                                <h1 className="text-txtcolor font-bold  text-base ">{t("No available services")}</h1>
                            </div>
                        )}
                    </div>
                    <ManageSubmit />
                </div>
            ) : ( */}
            <div className="flex -lg:mx-[10px]  pb-[80px] h-[calc(100vh-268px)] lg:h-[calc(100vh-351px)]  overflow-y-scroll scrollbar-hide flex-wrap md:pb-3 content-baseline ">
                {isLoading ? (
                    <div className="flex justify-center just w-full px-2">
                        <Spinner size="xl" className="" />
                    </div>
                ) : filteredServices.length <= 0 && !isShopLocationServicesLoading ? (
                    <div className=" justify-center flex w-full h-full items-center px-2">
                        <h1 className="text-center text-textGrey text-base font-normal ">{t("No available services")}</h1>
                    </div>
                ) : (
                    <div className="w-full gap-4 flex flex-col px-2">
                        {bookingState?.tempStore?.selectedServices?.map((serviceData: ISpecialistService, index: number) => (
                            <ServiceCardComponent
                                key={`service-${serviceData.id}-${index}`}
                                serviceData={serviceData}
                                removeable={true}
                                handleRemoveService={() => handleRemoveService(index)}
                                isSelected
                            />
                        ))}
                        {bookingState?.tempStore?.selectedServices.length > 0 && (
                            <div className="font-semibold text-[14px] text-txtAppointmentColor pl-[3px] leading-[16.8px] -tracking-[0.03rem] my-1"> {t("Would you like to add anything else?")}</div>
                        )}
                        {filteredServices.map((serviceData: ISpecialistService, index: number) => (
                            <Fragment key={`${serviceData.id}-${index}`}>
                                {serviceData?.children && serviceData?.children?.length > 0 ? (
                                    <Collapse
                                        title={serviceData?.children[0]?.parent?.name}
                                        description={`${serviceData?.children?.length} Options`}
                                        isCollapsed={false}
                                        classNames="w-full text-txtcolor"
                                    >
                                        <div className="border-t border-t-borderColorPrimary mt-4">
                                            <div className="mt-4 gap-4 flex flex-col">
                                                {serviceData.children.map((child: any) => (
                                                    <ServiceCardComponent key={child.id} serviceData={child} />
                                                ))}
                                            </div>
                                        </div>
                                    </Collapse>
                                ) : (
                                    <ServiceCardComponent serviceData={serviceData} />
                                )}
                            </Fragment>
                        ))}
                    </div>
                )}
                {uiState.isMobile && <ManageSubmit />}
            </div>
            {/* )} */}
        </div>
    );
};

export default SelectServices;
