import React, { ReactNode } from "react";
import { Button } from "primereact/button";

interface IProps {
    onClick?: (event: any) => void;
    children?: ReactNode;
    className?: string;
    type?: "submit" | "reset" | "button" | undefined;
    disabled?: boolean;
    isLoading?: boolean;
    primary?: boolean;
    secondary?: boolean;
    outlinePrimary?: boolean;
    outlineSecondary?: boolean;
    icon?: ReactNode | undefined;
    rightIcon?: ReactNode | undefined;
    size?: string;
    borderClass?: string;
    iconOnlyClass?: string;
    shadow?: boolean;
}

const CustomButton = ({
    onClick,
    children,
    className = "",
    disabled = false,
    isLoading = false,
    type = "button",
    primary = false,
    secondary = false,
    outlinePrimary = false,
    outlineSecondary = false,
    icon = undefined,
    rightIcon = undefined,
    size = "",
    borderClass = "",
    iconOnlyClass = "",
    shadow = true,
}: IProps) => (
    <Button
        type={type}
        loading={isLoading}
        disabled={disabled}
        onClick={onClick}
        className={`flex justify-center gap-[4px] text-base leading-[18px] font-semibold px-3 py-[9px] h-[40px] rounded-secondary -tracking-[0.03rem] ${className} ${size} ${borderClass} ${iconOnlyClass} ${
            primary ? "bg-primary text-primaryText  " : ""
        } ${outlinePrimary ? "text-primary bg-white border border-primary hover:bg-primaryHover " : ""} ${
            secondary ? "bg-white border border-borderColorPrimary shadow-sm  text-gray-700 hover:bg-primaryHover hover:border-primaryBorderHover" : ""
        } ${outlineSecondary ? "text-txtAppointmentColor  border border-borderColorSecondary hover:bg-primaryHover hover:border-primaryBorderHover" : ""} ${
            disabled && secondary ? "!bg-gray-100 opacity-50  border border-borderColorSecondary text-black" : ""
        } ${shadow && "shadow-InputAndButton"} `}
    >
        {icon}
        {children}
        {rightIcon}
    </Button>
);

export default CustomButton;
