import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { addProduct, getBookingInfo, removeProduct, setPaymentOption, setPaymentType, setStep } from "../Booking.slice";
import { useTranslation } from "react-i18next";
import ProductCard from "./ProductCard";
import { axiosGet } from "src/utils/requestClient";
import { API } from "src/constants/api";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";
import ProductDetail from "./ProductDetail";
import { IInitAction, initAction } from "./Product.interface";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { EBookingTabState } from "../bookAppointment.interface";
import PopupModal from "src/components/PopupModal";
import Sidebar from "src/components/Sidebar";
import CustomButton from "src/components/CustomButton";

const ProductBooking = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const booking = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((data) => data.UiStates);
    const shop = useAppSelector(currentShop);
    const [allProducts, setAllProducts] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const [product, setProduct] = useState<any>(null);
    const [productVariant, setProductVariant] = useState<any>({});
    const [action, setAction] = useState<IInitAction>(initAction);

    useEffect(() => {
        dispatch(setStep(EBookingTabState.PRODUCT_BOOKING));
        getProducts();
        dispatch(setPaymentType("cash"));
        dispatch(setPaymentOption("pay_later"));
    }, []);

    useEffect(() => {
        if (!uiState.isMobile) {
            handleClose();
        }
    }, [uiState.isMobile]);

    useEffect(() => {
        if (allProducts.length) {
            let aggregatedProducts = booking.selectedProducts.reduce((acc: any, item: any) => {
                const id = item.selectedVariant.inventory.id;
                if (acc[id]) {
                    acc[id].quantity += 1;
                } else {
                    acc[id] = {
                        ...item,
                        id,
                        quantity: 1,
                    };
                }
                return acc;
            }, {});
            aggregatedProducts = Object.values(aggregatedProducts);

            let filteredProducts = [];

            if (booking.productCategoryFilterValue) {
                filteredProducts = allProducts.filter((ProductDetails) => ProductDetails.category.id === booking.productCategoryFilterValue.value);
            }

            const productsToProcess = filteredProducts.length > 0 ? filteredProducts : allProducts;

            const updatedProducts = productsToProcess.reduce((acc: any[], allProduct: any) => {
                const variants = allProduct.variants.reduce((variantsAcc: any[], variant: any) => {
                    const inventories = variant.inventories.reduce((inventoriesAcc: any[], inventory: any) => {
                        const aggregatedProduct = aggregatedProducts.find((item: any) => item.id === inventory.id);
                        const quantity = inventory.in_stock_quantity - (aggregatedProduct ? aggregatedProduct.quantity : 0);

                        if (quantity > 0) {
                            inventoriesAcc.push({ ...inventory, in_stock_quantity: quantity });
                        }
                        return inventoriesAcc;
                    }, []);

                    if (inventories.length > 0) {
                        variantsAcc.push({ ...variant, inventories, inventory: inventories[0] });
                    }
                    return variantsAcc;
                }, []);

                if (variants.length > 0) {
                    acc.push({ ...allProduct, variants });
                }
                return acc;
            }, []);

            setProducts(updatedProducts);
        }
    }, [booking, allProducts]);

    const getProducts = () => {
        const params = {
            shop_id: shop.id,
            location_id: booking.selectedShopLocation?.id,
        };
        const payload = {
            booking_id: booking.modifyingAppointmentId || null,
        };
        axiosGet(API.PRODUCT.LIST, payload, params).then((response) => setAllProducts(response.data.data));
    };

    const handleAction = (type: keyof IInitAction, data?: any) => (event: any) => {
        event.stopPropagation();
        if (type === "product") {
            setProduct(data);
        }
        setAction(() => ({ ...initAction, [type]: true }));
    };

    const handleClose = () => {
        setAction(initAction);
        setProductVariant({});
    };

    const manageCart =
        (type: string, index: number = 0) =>
        (item: any, variant: any) =>
        () => {
            if (type === "add") {
                dispatch(addProduct({ ...item, selectedVariant: variant }));
                handleClose();
            } else {
                dispatch(removeProduct(index));
            }
        };

    return (
        <>
            <div className="relative h-[calc(100%-255px)] lg:h-[calc(100%-70px)] lg:pt-[36px]">
                <div className="overflow-y-scroll scrollbar-hide h-full max-h-full">
                    {booking.selectedProducts.length > 0 && (
                        <>
                            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4 3xl:grid-cols-5 pt-[5px]">
                                {booking.selectedProducts.map((item, index) => (
                                    <ProductCard key={`${item.id} - ${index}`} product={item} onClick={manageCart("remove", index)} isselected handleAction={handleAction} isSelected />
                                ))}
                            </div>
                            <div className="font-semibold font-base text-txtAppointmentColor pb-4 px-2 -tracking-[0.03rem]">{t("Additional products you want to add")}</div>
                        </>
                    )}
                    <div className={`grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4 3xl:grid-cols-5 content-baseline `}>
                        {products.map((item) => (
                            <ProductCard key={item.id} product={item} onClick={manageCart("add")} handleAction={handleAction} />
                        ))}
                    </div>
                </div>
                {/* <Sidebar isOpen={action.product} isBottomView={uiState.isMobile} position={uiState.isMobile ? "bottom" : "right"} size={uiState.isMobile ? "" : "w-[460px]"} handleClose={handleClose}>
            </Sidebar> */}
            </div>
            {uiState.isMobile && <ManageSubmit />}
            {uiState.isMobile && action.product ? (
                <Sidebar isOpen={action.product && uiState.isMobile} position="bottom" handleClose={handleClose} isBottomView customClass="" size="w-[460px]" dismissable>
                    <div className="w-full flex flex-1 relative max-h-[calc(100vh-170px)]  bg-contentBackground ">
                        <ProductDetail data={product} productVariant={productVariant} setProductVariant={setProductVariant} />
                    </div>
                    <div className="flex w-full gap-3 absolute bottom-0 mb-5 bg-contentBackground">
                        <CustomButton className="w-full" secondary onClick={handleClose}>
                            Cancel
                        </CustomButton>
                        <CustomButton primary className="w-full" onClick={manageCart("add")(product, productVariant)}>
                            Continue
                        </CustomButton>
                    </div>
                </Sidebar>
            ) : (
                action.product && (
                    <PopupModal
                        onClose={handleClose}
                        size="max-w-[95%] min-w-[340px] mx-auto lg:w-[650px] font-primary outline-custom"
                        className="!pt-5 !pb-0"
                        isCrossSign={false}
                        primaryButton={t("Continue")}
                        secondaryButton={t("Cancel")}
                        acceptAction={manageCart("add")(product, productVariant)}
                        declineAction={handleClose}
                        dismissible
                        position="center"
                    >
                        <ProductDetail data={product} productVariant={productVariant} setProductVariant={setProductVariant} />
                    </PopupModal>
                )
            )}
        </>
    );
};

export default ProductBooking;
