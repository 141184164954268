import React from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { getBookingInfo, setIsShowNoShowPolicy, setIsShowOrderSummary } from "./Booking.slice";
import Sidebar from "src/components/Sidebar";
import AppointmentPreview from "./AppointmentPreview";
import NoShowPolicy from "./NoShowPolicy";
import PopupModal from "src/components/PopupModal";
import { useBookingConditionsStatus } from "src/hooks/useBookingConditionsStatus";
import { useShopNavigation } from "src/hooks/useShopNavigation";

const ConditionalNoShowView = () => {
    const booking = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((data) => data.UiStates);
    const { hasSelectedRequiredFields } = useBookingConditionsStatus();
    const { syncTempStoreOnClose } = useShopNavigation();
    const dispatch = useAppDispatch();
    const handleClose = () => {
        if (hasSelectedRequiredFields) {
            syncTempStoreOnClose(booking);
        }
        dispatch(setIsShowNoShowPolicy(false));
    };
    return (
        <>
            <Sidebar
                isOpen={booking.isShowOrderSummary && uiState.isMobile}
                handleClose={() => dispatch(setIsShowOrderSummary(false))}
                position={`${uiState.isMobile ? "bottom" : "right"}`}
                size={`${uiState.isMobile ? "w-full" : "w-[460px]"}`} // w-[460px]"
                customClass=""
                isBottomView={uiState.isMobile ? true : false}
            >
                <AppointmentPreview />
            </Sidebar>
            <Sidebar
                dismissable
                isOpen={booking.isShowNoShowPolicy && uiState.isMobile}
                handleClose={handleClose}
                position={`${uiState.isMobile ? "bottom" : "right"}`}
                size={`${uiState.isMobile ? "w-full" : "w-[460px]"}`}
                customClass=""
                isBottomView={uiState.isMobile ? true : false}
            >
                <NoShowPolicy />
            </Sidebar>

            {booking.isShowNoShowPolicy && !uiState.isMobile && (
                <PopupModal
                    onClose={handleClose}
                    size="max-w-[95%] min-w-[340px] mx-auto lg:w-[540px] font-primary outline-custom"
                    className="!pt-5 !pb-0 "
                    isCrossSign={false}
                    dismissible
                    position="center"
                >
                    <NoShowPolicy />
                </PopupModal>
            )}
        </>
    );
};

export default ConditionalNoShowView;
