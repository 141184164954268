import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setNotificationToken } from "../../redux/reducers/Login.slice";
import { errorCode } from "../../constants/common";
import { toast } from "react-toastify";
import { onMessageListener, requestForFirebaseToken } from "../../services/API/firebase";
import { axiosPost } from "../../utils/requestClient";
import { API } from "../../constants/api";
import Template1Header from "../Landing/Template1/Header";
import Template2Header from "../Landing/Template2/Header";
import CommonHeader from "./CommonHeader";
import { currentUser } from "src/redux/reducers/common/Common.slice";
import { XClose } from "@untitled-ui/icons-react/build/cjs";
import { AppLogo } from "src/theme/Images";
import CustomButton from "src/components/CustomButton";

const Header = () => {
    const dispatch = useAppDispatch();
    const AuthUserState = useAppSelector((data) => data.AuthUser);
    const user = useAppSelector(currentUser);
    const template = useAppSelector((data) => data.Common.template);

    useEffect(() => {
        const getOrUpdateFirebaseNotificationToken = async () => {
            let FCM_TOKEN = await requestForFirebaseToken();
            if (!FCM_TOKEN) {
                FCM_TOKEN = await requestForFirebaseToken();
            }

            if (FCM_TOKEN && FCM_TOKEN !== AuthUserState.notificationToken && user) {
                dispatch(setNotificationToken(FCM_TOKEN));

                try {
                    const response = await axiosPost(API.NOTIFICATION.CREATE_UPDATE, {
                        token: FCM_TOKEN,
                    });
                    if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                        return;
                    }

                    throw response.data;
                } catch (err: any) {
                    toast.error(err?.message);
                }
            }
        };

        getOrUpdateFirebaseNotificationToken();

        if (user) {
            onMessageListener()
                .then((payload: any) => {
                    if (payload?.notification?.title !== "Appointment Requested") {
                        toast.success(payload?.notification?.body);
                    }
                })
                .catch((err) => {});
        }
    }, [user, AuthUserState.notificationToken, dispatch]);

    const renderHeader = () => {
        switch (template) {
            case "template_1":
                return <Template1Header />;
            case "template_2":
                return <Template2Header />;
            default:
                return <CommonHeader />;
        }
    };

    return (
        <>
            <div className="relative top-0 flex items-center py-[14px] px-4 bg-white border-b-[2px] border-[#E3E7EF] lg:hidden">
                <div className="mr-[6px]">
                    <XClose className="w-5 h-5 text-textGrey" />
                </div>
                <div className="flex-1 flex items-center">
                    <img src={AppLogo} alt="" className="rounded-[6px]" />
                    <div className="flex flex-col ml-4">
                        <span className="text-txtAppointmentColor font-bold text-[14px] leading-[21px] tracking-[-0.04rem]">Get the app</span>
                        <span className="leading-[15px] text-[10px] font-normal text-txtAppointmentColor">For the best user experience</span>
                    </div>
                </div>
                <CustomButton primary className="btn-appointment-primary text-primary !px-2 !py-[2px] !h-[24px]  font-bold !text-[12px] whitespace-nowrap">
                    Get App
                </CustomButton>
            </div>
            {renderHeader()}
        </>
    );
};

export default Header;
