import React, { FC, useEffect, useState } from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import InputWithLabel from "../../../components/InputWithLabel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { errorCode, GLOBALVARIABLE } from "../../../constants/common";
import { axiosPatch } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { checkFileTypeValidation, convertBase64ToFile, getShortName, logout } from "src/utils/global-functions";
import { s3Upload } from "src/utils/s3Operations";
import { s3Path } from "src/utils/s3Path";
import { IProfileProps, ISocialProfile } from "../Auth.interface";
import Tooltip from "src/components/Tooltip/Tooltip";
import CustomButton from "src/components/CustomButton";
import { currentShop, currentUser, me } from "src/redux/reducers/common/Common.slice";
import { usePhone } from "src/hooks/usePhone";
import PhoneOrEmail from "src/components/PhoneOrEmail";
import { ChevronLeft, InfoCircle, User01 } from "@untitled-ui/icons-react/build/cjs";
import { userLogout } from "src/redux/reducers/Login.slice";
import CropperModal from "src/components/CropperModal/CropperModal";

const SocialProfile: FC<IProfileProps> = ({ setAuthData, handleAction, upload, setUpload }) => {
    const { t } = useTranslation();
    const { isPhoneValid } = usePhone();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const uiState = useAppSelector((data) => data.UiStates);
    const [isLoading, setIsLoading] = useState(false);
    const [referralConfig, setReferralConfig] = useState({ status: false, referee: 0, refferer: 0, referee_type: "percentage", refferer_type: "percentage" });
    const [errorImage, setErrorImage] = useState({
        profile: false,
    });
    const [cropData, setCropData] = useState<any>("");
    const [image, setImage] = useState<any>("");
    const [file, setFile] = useState<any>({ convertedFile: "", filename: "" });

    const schema = Yup.object({
        first_name: Yup.string().required(t("This field is required")),
        last_name: Yup.string().required(t("This field is required")),
        phone_country_code: Yup.string().required(t("This field is required")),
        phone: Yup.string()
            .required(t("This field is required"))
            .test({
                message: t("Invalid phone number"),
                test: function (value) {
                    const { phone_country_code: phoneCountryCode } = this.parent;
                    const isValid = isPhoneValid(value as string, phoneCountryCode);
                    return isValid;
                },
            }),
        profile_image_name: Yup.string().nullable(),
        referral_code: Yup.string().notRequired(),
    }).required();

    const { handleSubmit, control, setError, setValue, watch, clearErrors } = useForm<ISocialProfile>({
        resolver: yupResolver(schema) as Resolver<ISocialProfile>,
        defaultValues: {
            first_name: user?.first_name || "",
            last_name: user?.last_name || "",
            phone_country_code: user?.phone_country_code || "",
            phone: user?.phone || "",
            profile_image_name: null,
            referral_code: "",
        },
    });
    const firstName = watch("first_name");
    const lastName = watch("last_name");

    useEffect(() => {
        const shopSetting = shop.shop_settings.find((shop_setting: { type: string }) => shop_setting.type === "refer");
        if (shopSetting) {
            setReferralConfig({
                status: shopSetting.status || false,
                referee: shopSetting.value.referee || 0,
                referee_type: shopSetting.value.referee_type || "percentage",
                refferer: shopSetting.value.refferer || 0,
                refferer_type: shopSetting.value.refferer_type || "percentage",
            });
        }
    }, []);

    const handleChange = (data: ISocialProfile) => {
        axiosPatch(API.USER.SOCIAL_ACCOUNT, data, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
                setAuthData((old: any) => ({ ...old, phone: data.phone, phone_country_code: data.phone_country_code }));
                handleAction("otp")();
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof ISocialProfile, {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        return;
                    }
                }
                toast.error(response.message);
            })
            .finally(() => setIsLoading(false));
    };
    const handleSave = async (payloadData: ISocialProfile) => {
        setIsLoading(true);

        if (file.convertedFile && file?.filename) {
            const logoImageResponse: any = await s3Upload(file.convertedFile, `${s3Path.USER_PROFILE}${file?.filename}`);
            const imageStatus = logoImageResponse?.status;
            if (imageStatus === errorCode.updateSuccess) {
                const updatedData = {
                    ...payloadData,
                    profile_image_name: file?.filename,
                };
                handleChange(updatedData);
            } else {
                setIsLoading(false);
            }
        } else {
            const updatedData = {
                ...payloadData,
                profile_image_name: payloadData.profile_image_name,
            };
            handleChange(updatedData);
        }
    };

    const onChangeCrop = (e: any) => {
        e.preventDefault();
        setUpload(false);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.accountImageSize);

        if (result) {
            clearErrors("profile_image_name");
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage("");
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        } else {
            setError("profile_image_name" as "profile_image_name", {
                type: "manual",
                message: message,
            });
        }
    };

    const imageUploadFun = (data: any) => {
        const fileInfo = convertBase64ToFile(data);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue("profile_image_name", fileInfo.filename);
        }
    };

    const onErrorImage = (name: string) => () => {
        setErrorImage((old) => ({ ...old, [name]: true }));
    };

    const handleLogout = async () => {
        const result = await dispatch(userLogout({}));

        if (result.type === userLogout.fulfilled.toString()) {
            logout();
        }

        if (result.type === userLogout.rejected.toString()) {
            const response = result.payload.data;
            toast.error(response.message);
        }
    };

    return (
        <div className="content font-primary lg:m-[50px] lg:mt-[30px]">
            <div className="flex flex-col">
                <h1 className="login-model-title">{t("Complete your profile")}</h1>
                <p className="login-model-subtitle">Finalize your account set up in less than 30 seconds</p>
            </div>
            <form onSubmit={handleSubmit(handleSave)} className="w-full mt-[30px] flex flex-col">
                <div className={`flex flex-col justify-center`}>
                    <div className="flex flex-col">
                        <Controller
                            name="profile_image_name"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className="w-full flex flex-col">
                                    <div className="flex flex-row gap-4 lg:gap-5 relative">
                                        {!errorImage.profile && cropData ? (
                                            <img src={cropData} alt="" onError={onErrorImage("profile")} className="w-16 h-16 border-[0.75px] border-[#F2F4F7] rounded-lg object-cover" />
                                        ) : firstName && lastName ? (
                                            <div className="rounded-lg bg-[#F2F4F7] flex justify-center items-center  text-primaryAppointment font-medium h-[64px] w-[64px] ">
                                                <span className="text-[24px] leading-[36px] font-medium">{getShortName(`${firstName} ${lastName}`)}</span>
                                            </div>
                                        ) : (
                                            <div className="rounded-lg bg-[#F2F4F7] flex justify-center items-center  text-primaryAppointment font-medium h-[64px] w-[64px] ">
                                                <span className="text-[24px] leading-[36px] font-medium">
                                                    <User01 className="text-[#8C9CBB] w-5 h-5" />
                                                </span>
                                            </div>
                                        )}

                                        <div className={`rounded-lg border border-[#EAECF0] bg-white hover:shadow shadow-sm cursor-pointer flex-auto items-center justify-center py-[11px] px-0 `}>
                                            <div className="relative overflow-hidden cursor-pointer">
                                                <input type="file" id="image" className=" absolute opacity-0 rounded-full h-[64px] w-[64px]" onChange={onChangeCrop} />
                                                <label htmlFor="image" className="cursor-pointer text-center ">
                                                    <div className="text-textGrey text-[14px] leading-[20px] font-normal">
                                                        <p className="mb-1 flex items-center justify-center">
                                                            <span className="text-primary text-[14px] leading-[20px] font-semibold pr-1">Click to upload </span>
                                                            <span className=" text-textGrey text-[14px] leading-[20px] font-normal">or drag and drop</span>
                                                        </p>
                                                        <p className="text-[12px] text-textGrey font-normal leading-[18px]">PNG or JPG</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {error && <p className="text-error">{error.message}</p>}
                                </div>
                            )}
                        />
                        <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 mt-[24px] mb-4">
                            <Controller
                                name="first_name"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="w-full">
                                        <InputWithLabel
                                            label={t("First Name")}
                                            borderClass="!h-[44px]"
                                            required
                                            type="text"
                                            placeholder={t("Enter your first name")}
                                            handleChange={onChange}
                                            value={value}
                                            name="first_name"
                                        />
                                        {error && <p className="text-red-500">{error.message}</p>}
                                    </div>
                                )}
                            />
                            <Controller
                                name="last_name"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="w-full">
                                        <InputWithLabel
                                            label={t("Last Name")}
                                            borderClass="!h-[44px]"
                                            required
                                            type="text"
                                            placeholder={t("Enter your last name")}
                                            handleChange={onChange}
                                            value={value}
                                            name="last_name"
                                        />
                                        {error && <p className="text-red-500">{error.message}</p>}
                                    </div>
                                )}
                            />
                        </div>
                        <Controller
                            name="phone"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="w-full">
                                    <label className="text-sm text-txtAppointmentColor leading-[18.2px] flex font-normal text-[14px] -tracking-[0.02rem] mb-1.5">
                                        {t("Phone")} <span className="asterisk">*</span>
                                    </label>
                                    <PhoneOrEmail
                                        singleType="phone"
                                        inputType="phone"
                                        borderClass="!h-[44px]"
                                        onNumberChange={({ data: { country, code, number } }: any) => {
                                            setValue("phone", `+${code}${number}`);
                                            setValue("phone_country_code", country);
                                        }}
                                    />
                                    {error && <p className="text-error">{error.message}</p>}
                                </div>
                            )}
                        />

                        {referralConfig.status && (
                            <Controller
                                name="referral_code"
                                control={control}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                    <div className="mb-5 md:w-full mt-4">
                                        <div className="flex flex-row  mb-1.5 items-center">
                                            <span className="text-txtAppointmentColor text-[14px] font-normal leading-[18.2px] -tracking-[0.03rem]">Referral code</span>
                                            <Tooltip
                                                description={t("Receive 0% off any service when you refer a friend!", {
                                                    value: referralConfig.refferer_type === "percentage" ? `${referralConfig.refferer}%` : `$${referralConfig.refferer}`,
                                                })}
                                            >
                                                <InfoCircle className="text-primaryAppointment ml-1.5 cursor-pointer w-[14px]" />
                                            </Tooltip>
                                            {/* <Tooltip
                                                content={
                                                    <div>
                                                        <p className=" text-primaryAppointment text-xs font-normal">
                                                            {t("Receive 0% off any service when you refer a friend!", {
                                                                value: referralConfig.refferer_type === "percentage" ? `${referralConfig.refferer}%` : `$${referralConfig.refferer}`,
                                                            })}
                                                        </p>
                                                    </div>
                                                }
                                                trigger="hover"
                                                style={"light" as any}
                                                className="w-[185px] bg-[#EDF1FD] p-3 rounded-lg "
                                                placement="right"
                                            >
                                                <BiInfoCircle className="text-primaryAppointment ml-1.5 cursor-pointer w-[16px]" />
                                            </Tooltip> */}
                                        </div>
                                        <InputWithLabel
                                            // label={t("Referral code (Optional)")}
                                            type="text"
                                            placeholder={t("Enter code")}
                                            handleChange={(e) => onChange(e.target.value.toUpperCase())}
                                            value={value ?? ""}
                                            name="referral_code"
                                        />
                                        {error && <p className="text-red-500">{error.message}</p>}
                                        <p className="text-secondaryTxt text-xs font-normal leading-4 -tracking-[0.03px] mt-2">
                                            {t("Enter a referral code to receive")}
                                            &nbsp;
                                            <span className="font-semibold">{referralConfig.referee_type === "percentage" ? `${referralConfig.referee}%` : `$${referralConfig.referee}`}</span>
                                            &nbsp;
                                            {t("your first appointment")}
                                        </p>
                                    </div>
                                )}
                            />
                        )}
                    </div>
                </div>
                <div className="pt-4">
                    <p className="text-txtcolor text-[12px] leading-[20px] -tracking-[0.01rem] font-normal text-left ">
                        {t("By signing up, I agree to the")}&nbsp;
                        <a href="https://www.getflair.ca/terms-of-service" target="_blank" className="text-primary font-semibold">
                            {t("Terms of Service")}
                        </a>
                        &nbsp;{t("and")}&nbsp;
                        <a href="https://www.getflair.ca/privacy-policy" target="_blank" className="text-primary font-semibold">
                            {t("Privacy Policy")}
                        </a>
                        &nbsp;
                        {t("and I would like to be alerted for personalized offerings, discounts, and more.")}
                    </p>
                </div>
                <div className="flex flex-col mt-[30px] max-lg:mb-[30px]">
                    <CustomButton primary type="submit" size="w-full" className="!h-[48px] " isLoading={isLoading} disabled={isLoading}>
                        {uiState.isMobile ? t("Next") : t("Continue")}
                    </CustomButton>
                    <CustomButton
                        type="button"
                        icon={<ChevronLeft width={20} />}
                        className="!border-0 ring-0 !p-0 !w-[32px] !h-[32px] absolute -left-[11px] lg:left-[12px] -top-[18px] lg:top-[12px] hover:!bg-gray-100 !rounded-lg  !bg-transparent text-center text-textGrey hover:!text-txtcolor"
                        shadow={false}
                        onClick={handleLogout}
                    ></CustomButton>
                </div>
            </form>
            {upload && (
                <CropperModal
                    imageUpload={imageUploadFun}
                    imageUrl={image}
                    setUpload={setUpload}
                    setCropData={setCropData}
                    defaultCropType="Square"
                    title="Crop Image"
                    description="Upload a 400 x 400px image for best results."
                    isCropType={false}
                />
            )}
        </div>
    );
};

export default SocialProfile;
