import React, { useEffect, useLayoutEffect, useState } from "react";
import BarberSearchDetailView from "./BarberSearchDetailView";
import BarberSearchMapView from "./BarberSearchMapView";
import { ILocation, IShopLocation } from "../../Interface";
import { toast } from "react-toastify";
import { errorCode } from "../../constants/common";
import { axiosGet } from "../../utils/requestClient";
import { API } from "../../constants/api";
import { useAppSelector } from "../../redux/hooks";
import MapAdvance from "../../components/MapAdvance";
import { useTranslation } from "react-i18next";
import { ArrowBackSVG } from "src/theme/Images";
import FlowbiteModal from "../../components/FlowbiteModal";
import DownloadApp from "./DownloadApp";
import Sidebar from "src/components/Sidebar";
import { currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import { Download01 } from "@untitled-ui/icons-react/build/cjs";
import MapBox from "./MapBox";
import CustomButton from "src/components/CustomButton";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import Booking from "./Booking";

export type IActionType = {
    downloadAppInfo: boolean;
};
const initAction: IActionType = {
    downloadAppInfo: false,
};
const Location = () => {
    const { t } = useTranslation();
    const user = useAppSelector(currentUser);
    const uiState = useAppSelector((data) => data.UiStates);
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const [shopLocationData, setShopLocationData] = useState<IShopLocation[]>([]);
    const [shopLocationFilteredData, setShopLocationFilteredData] = useState<IShopLocation[]>([]);
    const [isShopsDataLoading, setIsShopsDataLoading] = useState(false);
    const [searchParam, setSearchParam] = useState<string | undefined>(undefined);
    const [locationCoordinate, setLocationCoordinate] = useState<ILocation | undefined>(undefined);
    const [selectedShopData, setSelectedShopData] = useState<IShopLocation | undefined>(undefined);
    const [showAppointmentShopOnMap, setShowAppointmentShopOnMap] = useState<IShopLocation | undefined>(undefined);
    const [action, setAction] = useState(initAction);
    const shop = useAppSelector(currentShop);
    const [isLocationView, setIsLocationView] = useState(false);

    useLayoutEffect(() => {
        navigateBasedOnShopLocations();
    }, []);

    const handleAction =
        (actionType: keyof IActionType | null = null) =>
        () => {
            if (actionType) {
                setAction({
                    ...initAction,
                    [actionType]: true,
                });
            } else {
                setAction(initAction);
            }
        };
    useEffect(() => {
        loadShopData({});
    }, []);

    useEffect(() => {
        if (searchParam === undefined) {
            return;
        }
        const filteredLocationList = shopLocationData.filter(
            (location) => location.name.toLowerCase().includes(searchParam.toLowerCase()) || location.street.toLowerCase().includes(searchParam.toLowerCase()),
        );
        setShopLocationFilteredData(filteredLocationList);
    }, [searchParam]);

    const handlerNearbyBtnClick = () => {
        setIsShopsDataLoading(true);
        if (locationCoordinate) {
            setLocationCoordinate(undefined);
            loadShopData({});
            return;
        } else {
            const location = window.navigator && window.navigator.geolocation;
            if (location) {
                location.getCurrentPosition(
                    (position) => {
                        setLocationCoordinate({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        });
                        const payload = {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        };
                        loadShopData(payload);
                    },
                    (error) => {
                        setLocationCoordinate(undefined);
                        setIsShopsDataLoading(false);
                    },
                );
            }
        }
    };

    const loadShopData = async (payloadObj: any) => {
        if (shop && shop.id) {
            setIsShopsDataLoading(true);
            await axiosGet(API.LOCATION.LIST, payloadObj, {
                shop_id: shop.id,
            })
                .then((response) => {
                    const data = response.data.data;

                    setShopLocationData(response.data.data);
                    setShopLocationFilteredData(response.data.data);
                })
                .catch((err: any) => toast.error(err?.message ?? "Something went wrong, Please try again"))
                .finally(() => setIsShopsDataLoading(false));
        }
    };
    const handleClose = () => {
        setIsLocationView(!isLocationView);
    };
    return (
        <>
            {uiState.isMobile && showAppointmentShopOnMap ? (
                <div className="w-full ">
                    <div className="flex items-center px-6 py-4 gap-[15px]">
                        <img src={ArrowBackSVG} alt="" onClick={() => setShowAppointmentShopOnMap(undefined)} />
                        <h1 className="text-base  text-txtcolor font-bold">{t("Go back")}</h1>
                    </div>
                    <div className="absolute w-full h-[calc(100vh-116px)]">
                        <MapAdvance shopLocationData={[]} selectedShopData={selectedShopData} showAppointmentShopOnMap={showAppointmentShopOnMap} setSelectedShopData={setSelectedShopData} />
                    </div>
                </div>
            ) : (
                <>
                    <div className="w-full h-full p-2 lg:p-8 bg-sideBackground">
                        <div className="rounded-[16px] lg:rounded-3xl bg-contentBackground h-[calc(100vh-(60px+16px))] lg:h-[calc(100vh-(60px+64px))] w-full flex overflow-hidden lg:shadow-md">
                            {/* {!uiState.isMobile && ( */}
                            <>
                                <BarberSearchDetailView
                                    searchParam={searchParam}
                                    handlerSearchChange={setSearchParam}
                                    handlerNearbyBtnClick={handlerNearbyBtnClick}
                                    shopLocationData={shopLocationFilteredData}
                                    isShopsDataLoading={isShopsDataLoading}
                                    selectedShopData={selectedShopData}
                                    setSelectedShopData={setSelectedShopData}
                                    locationCoordinate={locationCoordinate}
                                />
                                {!uiState.isMobile && <MapBox locations={shopLocationData} selectedShopData={selectedShopData} />}
                            </>
                            {/* )} */}

                            {/* <BarberSearchMapView
                                shopLocationData={shopLocationFilteredData}
                                selectedShopData={selectedShopData}
                                setSelectedShopData={setSelectedShopData}
                                showAppointmentShopOnMap={showAppointmentShopOnMap}
                                setShowAppointmentShopOnMap={setShowAppointmentShopOnMap}
                            /> */}

                            {/* {authUserState.cookieSettingAnswer === undefined && <Cookies />} */}
                            {/* <QrCode /> */}

                            {/* <div className="flex justify-center items-center spacing-lg absolute right-0 bottom-[126px] p-8 sm:bottom-0 z-10 md:hidden">
                                <CustomButton primary className="" onClick={handleAction("downloadAppInfo")} icon={<Download01 width="20" />}>
                                    Download app
                                </CustomButton>
                            </div> */}
                            {action.downloadAppInfo &&
                                (uiState.isMobile ? (
                                    <Sidebar isOpen={true} position="bottom" handleClose={handleClose} customClass={`w-full rounded-t-[24px] ${"h-[80%]"}`} size={""}>
                                        <DownloadApp handleClose={handleAction()} />
                                    </Sidebar>
                                ) : (
                                    <FlowbiteModal handleClose={handleAction()} size="md" position="bottom-right">
                                        <DownloadApp handleClose={handleAction()} />
                                    </FlowbiteModal>
                                ))}
                        </div>
                    </div>
                    {uiState.isMobile && (
                        <>
                            {/* <div className="flex-1 lg:block relative booking-container-wrapper">{user && <Booking />}</div> */}
                            {/* <BarberSearchDetailView
                                searchParam={searchParam}
                                handlerSearchChange={setSearchParam}
                                handlerNearbyBtnClick={handlerNearbyBtnClick}
                                shopLocationData={shopLocationFilteredData}
                                isShopsDataLoading={isShopsDataLoading}
                                selectedShopData={selectedShopData}
                                setSelectedShopData={setSelectedShopData}
                                locationCoordinate={locationCoordinate}
                            /> */}
                            {/* <BarberSearchMapView
                                shopLocationData={shopLocationFilteredData}
                                selectedShopData={selectedShopData}
                                setSelectedShopData={setSelectedShopData}
                                showAppointmentShopOnMap={showAppointmentShopOnMap}
                                setShowAppointmentShopOnMap={setShowAppointmentShopOnMap}
                            /> */}
                            {/* <div className="absolute w-full rounded-t-[24px] h-[15%] bottom-0 bg-contentBackground " onClick={handleClose}>
                                <div className="w-full lg:w-[460px] xl:w-[600px] font-primary">
                                    <div className="p-5 pb-0 lg:px-8  mt-2 lg:mt-[34px] w-full rounded-t-[24px]">
                                        <div className="flex flex-col mb-5">
                                            <h3 className="location-title-text">{t("Select a location")}</h3>
                                            <span className="location-sub-text">{t("Select from any of our locations below to get started with your booking.")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <Sidebar isOpen={isLocationView} position="bottom" handleClose={handleClose} customClass={`w-full rounded-t-[24px] ${"h-[80%]"}`} size={""}>
                                <div className="relative">
                                    <BarberSearchDetailView
                                        searchParam={searchParam}
                                        handlerSearchChange={setSearchParam}
                                        handlerNearbyBtnClick={handlerNearbyBtnClick}
                                        shopLocationData={shopLocationFilteredData}
                                        isShopsDataLoading={isShopsDataLoading}
                                        selectedShopData={selectedShopData}
                                        setSelectedShopData={setSelectedShopData}
                                        locationCoordinate={locationCoordinate}
                                    />
                                </div>
                            </Sidebar> */}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Location;
