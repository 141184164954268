import React from "react";
import { useShopNavigation } from "src/hooks/useShopNavigation";

interface Props {
    data: any;
    sectionIndex: number;
}
const Course: React.FC<Props> = ({ data, sectionIndex }) => {
    const { navigateBasedOnShopLocations } = useShopNavigation();

    return (
        <div className="">
            <div className="w-full flex items-center mb-4">
                <div className="slice-label">
                    <span className="num">{sectionIndex}</span>
                    <span className="slice-text">ACADEMY</span>
                </div>
            </div>
            <div className="h-[250px] md:h-[250px] lg:h-[295px] xl:h-[375px] 2xl:h-[425px] 3xl:h-[480px] object-cover">
                <img className="w-full object-cover h-full" src={data.image_url} alt="Barber at work" />
            </div>
            <div className="mt-6 lg:mt-[30px]">
                {/* <p className="sub_text uppercase mb-1">April 2024 (4 Days a Week)</p> */}
                <p className="sub_text uppercase mb-1">{data.date}</p>
                {data?.is_title && <h2 className="title_text">{data.title}</h2>}

                <div className="flex flex-col md:flex-row justify-between mb-4">
                    <p className="sub_text uppercase">Price : ${Number(data.price).toFixed(2)}</p>
                </div>

                {data.is_description && <p className="text-justify sub_text mb-5">{data.description}</p>}

                {data?.is_button && (
                    <button className="bg-primary text-white px-8 h-[42px] rounded-3xl" onClick={() => navigateBasedOnShopLocations()}>
                        {data.button}
                    </button>
                )}
            </div>
        </div>
    );
};

export default Course;
