import React, { useState, useEffect } from "react";
import AddNewCardTabComponent from "./AddNewCardTabComponent";
import Sidebar from "src/components/Sidebar";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";
import { API } from "../../../constants/api";
import { axiosGet, axiosPost } from "../../../utils/requestClient";
import { toast } from "react-toastify";
import { errorCode } from "../../../constants/common";
import { getCardTypeImage } from "../../../utils/global-functions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { LoyaltyPoint } from "../../../components/SvgColor/svgIcons";
import { setIsShowFinalizeBooking, setPaymentType, setPaymentOption, getBookingInfo, setIsValidPaymentType, setStep, setIsCardAdded } from "../Booking.slice";
import { useTranslation } from "react-i18next";
import { calculateTotalTaxes } from "../../../utils/global-functions";
import { IProps, OptionType, PaymentMethod } from "./Payment.interface";
import { currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import { Plus } from "@untitled-ui/icons-react/build/cjs";
import { EBookingTabState } from "../bookAppointment.interface";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardTabComponent from "./CardTabComponent";
import { ProgressSpinner } from "primereact/progressspinner";
import { IIsLoading, initLoading } from "./FinalizeBooking.interface";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");
const FinalizeBooking = ({ handleBackToBooking }: IProps) => {
    const [isShowAddNewCardTab, setIsShowAddNewCardTab] = useState(false);
    const uiState = useAppSelector((data) => data.UiStates);
    const [isLoadCard, setIsLoadCard] = useState(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const booking = useAppSelector(getBookingInfo);
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState<IIsLoading>(initLoading);
    const [userCardList, setUserCardList] = useState([]);
    const [defaultCardId, setDefaultCardId] = useState<string | undefined>(undefined);
    const [servicesSubTotalPrice, setServicesSubTotalPrice] = useState("");
    const [servicesSubTotalTax, setServicesSubTotalTax] = useState("0.00");
    const [isLoyaltyPoint, setIsLoyaltyPoint] = useState(false);
    const [selectedOption, setSelectedOption] = useState<OptionType>("pay_now"); //<OptionType>(userCardList.length > 0 ? "stripe" : "cash");
    const [selectPaymentMethod, setPaymentMethod] = useState<PaymentMethod>("stripe"); //<PaymentMethod>(userCardList.length > 0 ? "pay_online" : "pay_at_shop");

    useEffect(() => {
        if (user) {
            const totalAmount = (parseFloat(servicesSubTotalPrice) + parseFloat(servicesSubTotalTax)).toFixed(2);
            setIsLoyaltyPoint(Number(totalAmount) <= user.total_loyalty_amount ? true : false);
        }
    }, [user, servicesSubTotalPrice, servicesSubTotalTax]);

    const handleLoading = (type: keyof IIsLoading, id: string | number | null = null) => {
        setIsLoading((prevState: any) => ({
            ...prevState,
            [type]: true,
            id,
        }));
    };

    const handleLoadingFalse = () => {
        setIsLoading(initLoading);
    };

    useEffect(() => {
        dispatch(setStep(EBookingTabState.FINALIZE_BOOKING));
        loadStripeCardList();

        const totalPrices = booking.selectedServices.reduce((prevPrice, serviceRecord) => {
            if (serviceRecord.price && serviceRecord.quantity) {
                return prevPrice + Number(serviceRecord.price?.price) * serviceRecord.quantity;
            }
            return prevPrice;
        }, 0);
        setServicesSubTotalPrice(totalPrices.toFixed(2));
        if (totalPrices && booking.selectedShopLocation?.sales_tax) {
            const subTotalTax = calculateTotalTaxes(
                totalPrices.toFixed(2),
                Number(booking.selectedShopLocation?.sales_tax?.gst),
                Number(booking.selectedShopLocation?.sales_tax?.hst),
                Number(booking.selectedShopLocation?.sales_tax?.pst),
            );
            setServicesSubTotalTax(subTotalTax);
        } else {
            setServicesSubTotalTax("0.00");
        }

        return () => {
            dispatch(setIsValidPaymentType(false));
        };
    }, []);

    useEffect(() => {
        if (isLoadCard) {
            loadStripeCardList();
            setIsLoadCard(false);
            dispatch(setIsValidPaymentType(false));
        }
    }, [isLoadCard]);

    const loadStripeCardList = async () => {
        try {
            handleLoading("cardList");
            const response = await axiosGet(API.STRIPE.CARD_LIST);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setUserCardList(response.data.data.cards);
                setDefaultCardId(response.data.data.default_card_id);
                if (response.data.data.cards.length > 0) {
                    dispatch(setIsCardAdded(true));
                    setPaymentMethod("stripe");
                    setSelectedOption("pay_now");
                    dispatch(setPaymentType("stripe"));
                    dispatch(setPaymentOption("pay_later"));
                } else {
                    dispatch(setIsCardAdded(false));
                }
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message ?? "Something went wrong, Please try again");
        } finally {
            handleLoadingFalse();
        }
    };

    const handleMakeCardDefault = async (paymentMethodId: string) => {
        if (defaultCardId === paymentMethodId) {
            return;
        }
        handleLoading("deafultCard", paymentMethodId);
        axiosPost(API.STRIPE.CARD_DEFAULT, { payment_method_id: paymentMethodId })
            .then(() => setDefaultCardId(paymentMethodId))
            .catch((error: any) => toast.error(error?.message ?? "Something went wrong, Please try again"))
            .finally(() => handleLoadingFalse());
    };

    const handleBackToBookingScreen = () => {
        dispatch(setIsShowFinalizeBooking(false));
        handleBackToBooking();
    };

    const handleOptionChange = async (option: OptionType) => {
        setSelectedOption(option);
        dispatch(setPaymentOption(option));
    };

    const handlePaymentMethodChange =
        (paymentMethod: PaymentMethod, id: any = null) =>
        (event: any) => {
            event.stopPropagation();
            setPaymentMethod(paymentMethod);
            dispatch(setPaymentType(paymentMethod));
            if (id !== null) {
                handleMakeCardDefault(id);
                setDefaultCardId(id);
            }
        };

    return (
        <div className="h-full relative">
            <div className="title mb-3 text-txtcolor font-medium justify-between text-2xl lg:mt-6 flex gap-3 items-center px-2 max-lg:flex-col">
                {/* <div
                    className=" flex justify-center items-center text-txtcolor h-8 w-8 cursor-pointer border border-borderColorSecondary rounded-primary shadow-InputAndButton hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)]"
                    onClick={handleBackToBookingScreen}
                >
                    <ArrowLeft className="text-txtAppointmentColor w-[18px]" />
                </div> */}
                <div className="flex flex-col">
                    <h5 className="text-txtcolor font-semibold text-base leading-[24px]">Payment Options</h5>
                    <span className="text-textGrey leading-[18px] font-normal text-[12px]">We require our clients to secure their booking with a card on file. You card will not be charged</span>
                </div>
                {shop.admin.is_verified_stripe_account && userCardList.length > 0 && (
                    <div className="flex justify-end">
                        <label className="text-primary font-medium cursor-pointer flex items-center text-sm leading-[18.2px] -tracking-[0.02rem]" onClick={() => setIsShowAddNewCardTab(true)}>
                            <Plus className="mr-2 mt-0 w-[16px]" />
                            <div className=""> {t("Add payment method")}</div>
                        </label>
                    </div>
                )}
            </div>

            <div className="flex flex-col px-2">
                {user && user.total_loyalty_points > 0 && (
                    <label
                        htmlFor="loyalty-points"
                        className={`flex justify-between bg-contentBackground border p-3 md:p-4 xl:p-6 gap-5 items-center rounded-secondary relative hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] ${
                            isLoyaltyPoint ? "cursor-pointer" : ""
                        } ${selectPaymentMethod === "loyalty" ? "border-primary bg-primaryHover" : "border-borderColorPrimary"}`}
                    >
                        <div className="flex gap-3 items-center w-full justify-between">
                            <div className={`flex flex-row items-center gap-5`}>
                                <div className="p-[10px] lg:p-[8px] xl:p-[9px] 2xl:p-[10px] border borderColorPrimary rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton">
                                    <LoyaltyPoint fillColor="var(--brand-color)" />
                                </div>
                                <div>
                                    <p className={`font-medium text-sm text-${isLoyaltyPoint ? "txtcolor" : "txtAppointmentColor"}`}>{t("Loyalty points")}</p>
                                    <p className="text-textGrey text-xs font-medium">
                                        {t("Available")}
                                        {" : "}
                                        {user.total_loyalty_points} {t("points")}
                                    </p>
                                </div>
                            </div>
                            <div className="mx-0">
                                <label className="radio-container">
                                    <input
                                        type="radio"
                                        id="loyalty-points"
                                        disabled={isLoyaltyPoint ? false : true}
                                        checked={selectPaymentMethod === "loyalty"}
                                        onChange={handlePaymentMethodChange("loyalty")}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </label>
                )}
                {isLoading.cardList ? (
                    <div>loading</div>
                ) : userCardList.length > 0 ? (
                    userCardList.map((userCard: any) => (
                        <label
                            key={userCard.id}
                            htmlFor={`card-${userCard.id}`}
                            className={`flex justify-between bg-contentBackground border p-3 md:p-4 xl:p-6 gap-5 items-center  rounded-secondary relative hover:border-primaryBorderHover mt-3 cursor-pointer hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] ${
                                defaultCardId === userCard.id && selectPaymentMethod === "stripe" ? "border-primaryBorderHover bg-primaryHover" : "border-borderColorPrimary"
                            }`}
                        >
                            <div className="flex gap-3 items-center w-full justify-between">
                                <div className="flex flex-row items-center gap-5">
                                    <div
                                        className={`p-[5px] lg:p-[8px] xl:p-[9px] 2xl:p-[5px] border borderColorPrimary rounded-secondary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton  ${
                                            defaultCardId === userCard.id && selectPaymentMethod === "stripe" ? "border-primaryBorderHover " : ""
                                        }`}
                                    >
                                        <img className=" w-[32px] h-[32px] object-contain object-center" src={getCardTypeImage(userCard.card.brand)} alt="" />
                                    </div>
                                    <div className="text-txtcolor leading-[140%] font-medium text-sm flex flex-col">
                                        <p className="text-base xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.03rem]">
                                            {userCard.card.brand} card ending {userCard.card.last4}
                                        </p>
                                        {defaultCardId === userCard.id && <p className="text-textGrey text-xs font-medium -tracking-[0.00rem]">{t("Default")}</p>}
                                    </div>
                                </div>
                                {isLoading.deafultCard && isLoading.id && isLoading.id === userCard.id ? (
                                    <ProgressSpinner style={{ width: "25px", height: "25px" }} className="radio-loader" strokeWidth="8" animationDuration="1.5s" />
                                ) : (
                                    <div className="mx-0">
                                        <label className="radio-container">
                                            <input
                                                type="radio"
                                                id={`card-${userCard.id}`}
                                                checked={defaultCardId === userCard.id && selectPaymentMethod === "stripe"}
                                                onChange={handlePaymentMethodChange("stripe", userCard.id)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                )}
                            </div>
                        </label>
                    ))
                ) : (
                    <Elements stripe={stripePromise}>
                        <CardTabComponent loadStripeCardList={loadStripeCardList} />
                    </Elements>
                )}

                {/* <div
                    // bg-contentBackground border border-lightgray aa class add karjo condition ma
                    className={` border border-borderColorPrimary p-3 md:p-4 xl:p-6 flex gap-5 items-center justify-between rounded-secondary relative cursor-pointer hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] ${
                        selectedOption === "pay_now" ? "border-primaryBorderHover bg-primaryHover " : ""
                    }`}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOptionChange("pay_now");
                    }}
                >
                    <div className="flex gap-3 items-center w-full justify-between">
                        <div className="flex flex-row items-center gap-5">
                            <div
                                className={`p-[5px] lg:p-[5px] xl:p-[5px] 2xl:p-[5px] border borderColorPrimary rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton ${
                                    selectedOption === "pay_now" ? "border-primaryBorderHover  " : ""
                                }`}
                            >
                                <CreditCard02 color="var(--brand-color)" width={20} />
                            </div>
                            <h2 className="text-base !leading-5 xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.03rem]">{t("Pay Now")}</h2>
                        </div>
                        <div className="mr-0">
                            <label className="radio-container">
                                <input
                                    type="radio"
                                    id="loyaltyPointsSelect"
                                    className="cursor-pointer bg-primary"
                                    disabled={isLoyaltyPoint ? false : true}
                                    checked={selectedOption === "pay_now"}
                                    onChange={() => handleOptionChange("pay_now")}
                                    readOnly={true}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div
                    className={` border  p-3 md:p-4 xl:p-6 flex gap-5 items-center justify-between rounded-secondary relative cursor-pointer mt-3 hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] ${
                        selectedOption === "pay_later" ? "border-primaryBorderHover bg-primaryHover" : "bg-contentBackground border-borderColorPrimary"
                    }`}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOptionChange("pay_later");
                    }}
                >
                    <div className="flex gap-3 items-center w-full justify-between">
                        <div className="flex flex-row items-center gap-5">
                            <div
                                className={`p-[5px] lg:p-[5px] xl:p-[5px] 2xl:p-[5px] border border-borderColorPrimary  rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton ${
                                    selectedOption === "pay_later" ? "border-primaryBorderHover " : ""
                                }`}
                            >
                                <Building02 width={20} color="var(--brand-color)" />
                            </div>
                            <h2 className="text-base !leading-5 xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.03rem]">{t("Pay Later")}</h2>
                        </div>
                        <div className="mr-0">
                            <label className="radio-container">
                                <input
                                    type="radio"
                                    id="loyaltyPointsSelect"
                                    className="cursor-pointer bg-primary"
                                    disabled={isLoyaltyPoint ? false : true}
                                    checked={selectedOption === "pay_later"}
                                    onChange={() => handleOptionChange("pay_later")}
                                    readOnly={true}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div> */}

                {/* <div className="bg-contentBackground border border-lightgray py-3 md:py-3 xl:py-3 pl-3 pr-6 flex gap-5 items-center justify-between rounded-xl relative cursor-pointer mt-3">
                    <div className="flex gap-3 items-center w-full justify-between">
                        <div className="flex flex-row items-center gap-5">
                            <div className="p-[5px] lg:p-[5px] xl:p-[5px] 2xl:p-[5px] border custom-icon-border rounded-lg w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton">
                                <BiWallet color="var(--icon-color)" size={20} height={20} />
                            </div>
                            <h2 className="mt-1 text-base !leading-[22.4px] lg:text-xs lg:leading-4  xl:text-sm xl:leading-5 2xl:text-base 2xl:leading-[22.4px]  text-txtcolor font-medium align-middle">
                                {t("Deposit")}
                            </h2>
                        </div>
                        <div className="mr-0">
                            <label className="radio-container">
                                <input
                                    type="radio"
                                    id="loyaltyPointsSelect"
                                    className="cursor-pointer bg-primary"
                                    disabled={isLoyaltyPoint ? false : true}
                                    checked={selectedOption === "loyalty"}
                                    onChange={() => handleOptionChange("loyalty", "loyalty_points")}
                                    readOnly={true}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div> */}

                {/* <div className="mt-5 pb-6 w-full px-2">
                    <div className=" text-txtcolor -tracking-[0.03rem] font-semibold text-base">{t("Payment Method")}</div>
                    <div className="location-sub-text !text-xs !leading-[18px]">{t("To protect their time FLAIR Studios requires booking with a payment method on file.")}</div>
                    {user && user.total_loyalty_points > 0 && (
                        <label
                            htmlFor="loyalty-points"
                            className={`flex justify-between bg-contentBackground border p-3 md:p-4 xl:p-6 gap-5 items-center rounded-secondary relative hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] ${
                                isLoyaltyPoint ? "cursor-pointer" : ""
                            } ${selectPaymentMethod === "loyalty" ? "border-primary bg-primaryHover" : "border-borderColorPrimary"}`}
                        >
                            <div className="flex gap-3 items-center w-full justify-between">
                                <div className={`flex flex-row items-center gap-5`}>
                                    <div className="p-[10px] lg:p-[8px] xl:p-[9px] 2xl:p-[10px] border borderColorPrimary rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton">
                                        <LoyaltyPoint fillColor="var(--brand-color)" />
                                    </div>
                                    <div>
                                        <p className={`font-medium text-sm text-${isLoyaltyPoint ? "txtcolor" : "txtAppointmentColor"}`}>{t("Loyalty points")}</p>
                                        <p className="text-textGrey text-xs font-medium">
                                            {t("Available")}
                                            {" : "}
                                            {user.total_loyalty_points} {t("points")}
                                        </p>
                                    </div>
                                </div>
                                <div className="mx-0">
                                    <label className="radio-container">
                                        <input
                                            type="radio"
                                            id="loyalty-points"
                                            disabled={isLoyaltyPoint ? false : true}
                                            checked={selectPaymentMethod === "loyalty"}
                                            onChange={handlePaymentMethodChange("loyalty")}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </label>
                    )}
                    {userCardList.length > 0 ? (
                        userCardList.map((userCard: any) => (
                            <label
                                key={userCard.id}
                                htmlFor={`card-${userCard.id}`}
                                className={`flex justify-between bg-contentBackground border p-3 md:p-4 xl:p-6 gap-5 items-center  rounded-secondary relative hover:border-primaryBorderHover mt-3 cursor-pointer hover:shadow-[0_2px_16px_0_rgba(0,0,0,0.10)] ${
                                    defaultCardId === userCard.id && selectPaymentMethod === "stripe" ? "border-primaryBorderHover bg-primaryHover" : "border-borderColorPrimary"
                                }`}
                            >
                                <div className="flex gap-3 items-center w-full justify-between">
                                    <div className="flex flex-row items-center gap-5">
                                        <div
                                            className={`p-[5px] lg:p-[8px] xl:p-[9px] 2xl:p-[5px] border borderColorPrimary rounded-secondary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton  ${
                                                defaultCardId === userCard.id && selectPaymentMethod === "stripe" ? "border-primaryBorderHover " : ""
                                            }`}
                                        >
                                            <img className=" w-[32px] h-[32px] object-contain object-center" src={getCardTypeImage(userCard.card.brand)} alt="" />
                                        </div>
                                        <div className="text-txtcolor leading-[140%] font-medium text-sm flex flex-col">
                                            <p className="text-base !leading-5 xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.03rem]">
                                                {userCard.card.brand} card ending {userCard.card.last4}
                                            </p>
                                            {defaultCardId === userCard.id && <p className="text-textGrey text-xs font-medium -tracking-[0.00rem]">{t("Default")}</p>}
                                        </div>
                                    </div>
                                    <div className="mx-0">
                                        <label className="radio-container">
                                            <input
                                                type="radio"
                                                id={`card-${userCard.id}`}
                                                checked={defaultCardId === userCard.id && selectPaymentMethod === "stripe"}
                                                onChange={handlePaymentMethodChange("stripe", userCard.id)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </label>
                        ))
                    ) : (
                        <div
                            className={`bg-bgContent border py-3 md:py-3 xl:py-3 tracking-[0.03rem] pl-3 pr-6 flex gap-5 items-center justify-between rounded-secondary relative mt-3 h-[66px] ${
                                booking.isValidPaymentType ? "border-danger" : "border-borderColorPrimary"
                            }`}
                        >
                            <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1 -tracking-[0.03rem]">
                                {isUserCardLoading ? t("Loading") : t("Card no available")}
                            </p>
                        </div>
                    )}
                    <div className="flex justify-end mt-3">
                        <label className="text-primary font-medium cursor-pointer flex items-center text-sm -tracking-[0.02rem]" onClick={() => setIsShowAddNewCardTab(true)}>
                            <Plus className="mr-2 mt-0 w-[16px]" />
                            <div className=""> {t("Add payment method")}</div>
                        </label>
                    </div>
                </div> */}

                {/* {userCardList.length > 0 ? (
                        <div className="flex w-full gap-3 bg-[#F6F8FE] p-2.5 border-0 rounded-xl  items-center">
                            <BiInfoCircle size={20} className="text-primary" />
                            <p className="text-sm leading-[140%] text-primary">
                                {t(
                                    "Your payment will be processed only after you accept the offer and select a date. The card added to the file will be used to secure your order. You will not be charged until you accept the order.",
                                )}{" "}
                                {booking.selectedSpecialist?.shop_staff?.[0]?.cancellation_window} {t("hours of the reservation.")}
                            </p>
                        </div>
                    ) : (
                        ""
                    )} */}
            </div>
            {/* <Sidebar
                isOpen={booking.isShowOrderSummary && uiState.isMobile}
                handleClose={() => dispatch(setIsShowOrderSummary(false))}
                position={`${uiState.isMobile ? "bottom" : "right"}`}
                size={`${uiState.isMobile ? "w-full" : "w-[460px]"}`} // w-[460px]"
                customClass=""
                isBottomView={uiState.isMobile ? true : false}
            >
                <AppointmentPreview />
            </Sidebar>
            <Sidebar
                dismissable
                isOpen={booking.isShowNoShowPolicy && uiState.isMobile}
                handleClose={() => dispatch(setIsShowNoShowPolicy(false))}
                position={`${uiState.isMobile ? "bottom" : "right"}`}
                size={`${uiState.isMobile ? "w-full" : "w-[460px]"}`}
                customClass=""
                isBottomView={uiState.isMobile ? true : false}
            >
                <NoShowPolicy />
            </Sidebar>

            {booking.isShowNoShowPolicy && !uiState.isMobile && (
                <PopupModal
                    onClose={() => dispatch(setIsShowNoShowPolicy(false))}
                    size="max-w-[95%] min-w-[340px] mx-auto lg:w-[540px] font-primary outline-custom"
                    className="!pt-5 !pb-0 "
                    isCrossSign={false}
                    dismissible
                    position="center"
                >
                    <NoShowPolicy />
                </PopupModal>
            )} */}
            {isShowAddNewCardTab && (
                <Sidebar
                    dismissable
                    isOpen={isShowAddNewCardTab}
                    handleClose={() => setIsShowAddNewCardTab(false)}
                    position={`${uiState.isMobile ? "bottom" : "right"}`}
                    size={`${uiState.isMobile ? "w-full" : "w-[460px]"}`} // w-[460px]"
                    customClass=""
                    isBottomView={uiState.isMobile ? true : false}
                    handleBack={() => setIsShowAddNewCardTab(false)}
                >
                    <AddNewCardTabComponent
                        closeAddNewCardTab={() => {
                            setIsShowAddNewCardTab(false);
                            setIsLoadCard(true);
                        }}
                    />
                </Sidebar>
            )}
            {uiState.isMobile && <ManageSubmit />}
        </div>
    );
};

export default FinalizeBooking;
