import React from "react";
import BarberDetailCard from "./BarberDetailCard";
import InputWithLabel from "../../../components/InputWithLabel";
import { Search } from "../../../components/SvgColor/svgIcons";
import { ILocation, IShopLocation } from "../../../Interface";
import { IoCloseCircle } from "react-icons/io5";
import BarLoader from "../../../components/BarLoader";
import { useTranslation } from "react-i18next";
import { XClose, MarkerPin01, SearchLg } from "@untitled-ui/icons-react/build/cjs";
import CustomButton from "src/components/CustomButton";

interface IProps {
    shopLocationData: IShopLocation[];
    searchParam: string | undefined;
    handlerSearchChange: (inputSearch: string) => void;
    handlerNearbyBtnClick: () => void;
    isShopsDataLoading: boolean;
    selectedShopData: IShopLocation | undefined;
    setSelectedShopData: React.Dispatch<React.SetStateAction<IShopLocation | undefined>>;
    locationCoordinate: ILocation | undefined;
}

const BarberSearchDetailView = ({
    shopLocationData,
    searchParam,
    handlerSearchChange,
    handlerNearbyBtnClick,
    isShopsDataLoading,
    selectedShopData,
    setSelectedShopData,
    locationCoordinate,
}: IProps) => {
    const { t } = useTranslation();
    return (
        <div className="w-full lg:w-[430px] xl:w-[480px] 2xl:w-[525px] 3xl:w-[600px] font-primary max-lg:p-4">
            <div className="sm:px-5 lg:px-8  pt-4 lg:pt-0 lg:mt-[20px]">
                <div className="flex flex-col mb-4 lg:mb-5">
                    <h3 className="location-title-text">{t("Select a location")}</h3>
                    <span className="location-sub-text hidden lg:block">{t("Select from any of our locations below to get started with your booking.")}</span>
                    <span className="location-sub-text lg:hidden">{t("Select a location to get started")}</span>
                </div>
                <div className="flex justify-between gap-2 h-[40px] search-box">
                    <div className="relative w-full">
                        <InputWithLabel
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => handlerSearchChange(e.target.value)}
                            name="search_location"
                            placeholder={t("Search")}
                            borderClass="!max-h-[40px]"
                            inputClass=" pl-[44px] w-full"
                            value={searchParam ?? ""}
                            buttonIcon={<SearchLg className="text-textGrey w-4" />}
                            handlerIconClick={() => handlerSearchChange("")}
                            buttonIconRight={searchParam ? <XClose className="text-secondaryTxt w-5 absolute top-[9px] right-3" /> : <span></span>}
                        />
                    </div>
                    {/* <button
                        className={`flex justify-center gap-1.5 items-center min-w-[44px] border border-primaryAppointment px-3  transition-all ease-in-out delay-100 font-semibold text-base bg-transparent text-primaryAppointment  rounded-secondary ${
                            locationCoordinate ? "bg-transparent" : "bg-transparent"
                        }`}
                        onClick={handlerNearbyBtnClick}
                    >
                        <MarkerPin01 className="text-lg text-primaryAppointment" />
                        <span className="font-semibold text-base bg-transparent text-primaryAppointment -tracking-[0.03rem]"> {t("Nearby")}</span>
                    </button> */}
                    <CustomButton outlinePrimary className="h-[40px] w-[40px] min-w-[40px] !px-[10.5px]  " onClick={handlerNearbyBtnClick} icon={<MarkerPin01 width="20" />}>
                        {/* <MarkerPin01 width="24" /> */}
                    </CustomButton>
                </div>
            </div>
            <div className="w-[90%] mx-auto mt-2">
                <BarLoader isLoading={isShopsDataLoading} />
            </div>
            {shopLocationData.length ? (
                <div className="lg:px-8 px-0 sm:px-5 xl:px-8 mt-[16px] lg:mt-8 left_sidebar_content overflow-auto lg:pb-[170px] max-lg:overflow-y-scroll h-full max-lg:-mx-4 max-lg:px-4 max-lg:pb-[115px]">
                    {shopLocationData.map((location: IShopLocation, index: number) => (
                        <BarberDetailCard location={location} key={index} selectedShopData={selectedShopData} setSelectedShopData={setSelectedShopData} />
                    ))}
                </div>
            ) : (
                !isShopsDataLoading && (
                    <div className="left_sidebar_content flex items-center mt-[22px] lg:mt-8 justify-center flex-1">
                        <div className="flex flex-col px-5 mb-12">
                            <p className="text-center text-textGrey text-base font-normal mb-3 -tracking-[0.03rem]">{t("There are no available locations that match your search.")}</p>
                            <div className="flex justify-center">
                                <CustomButton outlinePrimary className="">
                                    {t("Clear Search")}
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default BarberSearchDetailView;
