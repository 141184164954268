import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import SidebarPaymentElement from "./PaymentMethod.Element";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

interface IProps {
    closeAddNewCardTab: () => void;
}

const AddNewCardTabComponent = ({ closeAddNewCardTab }: IProps) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex flex-col font-primary relative  h-[calc(100%-20px)] w-full">
                <div className="flex flex-col mb-5 ">
                    <h3 className="text-[20px] mb-1.5 leading-[28px] font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">{t("Add card on file")}</h3>
                    <span className=" font-normal text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">{t("Add a card on file for seamless checkouts")}</span>
                </div>
                <Elements stripe={stripePromise}>
                    <SidebarPaymentElement handleClose={closeAddNewCardTab} successBtnText={t("Add Card")} />
                </Elements>
            </div>
        </>
    );
};
export default AddNewCardTabComponent;
