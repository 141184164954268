import React, { useEffect, useState } from "react";
import { addDays, format, isAfter, differenceInDays, isSameDay, isToday, subDays } from "date-fns";
import { useAppSelector } from "../../../../../redux/hooks";

type Props = {
    selectedDate: Date;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
    workingDays: string[];
};

const RangeCalnedarComponent: React.FC<Props> = ({ selectedDate, setSelectedDate, workingDays }) => {
    const uiState = useAppSelector((data) => data.UiStates);
    const [dateRangeArray, setDateRangeArray] = useState<any>([]);

    useEffect(() => {
        const pastDateLength = uiState.currentBreakpoint === "2XL" ? 6 : uiState.currentBreakpoint === "XL" ? 4 : 3;
        const futureDateLength = uiState.currentBreakpoint === "2XL" ? 7 : uiState.currentBreakpoint === "XL" ? 5 : 4;
        const currentDate = new Date();
        const isCurrentDate = isToday(selectedDate);
        const daysDifference = differenceInDays(selectedDate, currentDate);
        let pastDates;
        if (uiState.isMobile) {
            if (daysDifference < pastDateLength) {
                if (daysDifference == 0 && isCurrentDate == false) {
                    pastDates = Array.from({ length: daysDifference + 1 }, (_, index) => subDays(selectedDate, index + 1));
                } else {
                    pastDates = Array.from({ length: daysDifference }, (_, index) => subDays(selectedDate, index + 1));
                }
            } else {
                pastDates = Array.from({ length: pastDateLength }, (_, index) => subDays(selectedDate, index + 1));
            }
        } else {
            pastDates = Array.from({ length: pastDateLength }, (_, index) => subDays(selectedDate, index + 1));
        }
        const futureDates = Array.from({ length: futureDateLength }, (_, index) => addDays(selectedDate, index + 1));

        setDateRangeArray([...pastDates.reverse(), selectedDate, ...futureDates]);
    }, [selectedDate, uiState.currentBreakpoint]);

    // UI classes
    const getSelectedDateClasses = (dateRange: number) => {
        if (selectedDate && format(selectedDate, "d") === format(dateRange, "d")) {
            // before:content-[''] before:absolute before:w-[5px] before:h-[5px] before:bg-primary before:rounded-full before:top-[34px]  point mate
            if (workingDays.includes(format(dateRange, "EEEE").toLocaleLowerCase())) {
                return "bg-inputbg text-primaryAppointment before:content-[''] before:absolute before:w-[5px] before:h-[5px] before:bg-primary before:rounded-full before:top-[24px] lg:before:top-[34px] after:content-[''] after:absolute md:after:w-[58px] md:after:h-[58px] after:w-[38px] after:h-[38px] after:border-2 after:border-primary md:after:rounded-2xl after:rounded-lg ";
            }
            return "bg-inputbg after:content-[''] after:absolute md:after:w-[58px] md:after:h-[58px] after:w-[38px] after:h-[38px] after:border-1 after:border-primary md:after:rounded-xl after:rounded-lg";
        }
        const isFutureDate = isAfter(dateRange, new Date()) || isToday(dateRange);
        if (!isFutureDate) {
            return "";
        }
        if (workingDays.includes(format(dateRange, "EEEE").toLocaleLowerCase())) {
            return " text-primaryAppointment border-borderColorPrimary border-[1px] after:absolute md:after:w-[58px] md:after:h-[58px] after:w-[38px] after:h-[38px]";
        }
        if (isFutureDate) {
            return "border-line border-[1px] ";
        }
    };

    return (
        <div className="bg-background overflow-auto md:overflow-hidden scrollbar-hide relative w-full lg:max-w-full">
            <div className="flex gap-1 md:gap-5  justify-between pt-4 w-full md:pb-5 pb-4">
                {dateRangeArray.map((dateRange: number, index: number) => (
                    <div
                        key={index}
                        onClick={() => {
                            if ((isAfter(dateRange, new Date()) || isToday(dateRange)) && !isSameDay(selectedDate, new Date(dateRange))) {
                                setSelectedDate(new Date(dateRange));
                            }
                        }}
                        className={`w-full h-full flex justify-center flex-col items-center ${
                            isAfter(dateRange, new Date()) || isToday(dateRange) ? "cursor-pointer" : "cursor-not-allowed opacity-50"
                        }`}
                    >
                        <div
                            className={`md:w-[48px] md:h-[48px] w-[30px] h-[30px] relative flex justify-center items-center rounded-primary text-primaryAppointment md:text-base text-sm font-medium
                            ${getSelectedDateClasses(dateRange)}
                            `}
                        >
                            {format(dateRange, "d")}
                        </div>
                        <div className="md:w-[50px] w-[44px] md:mt-[13px] mt-[6px] h-[21px] leading-[140%] flex justify-center items-center text-secondaryTxt text-sm">{format(dateRange, "EE")}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RangeCalnedarComponent;
