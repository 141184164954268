import React, { FC, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { usePhone } from "src/hooks/usePhone";
import { IForgotPassword, IForgotPasswordProps, AuthDataInit } from "./Password.interface";
import { useTranslation } from "react-i18next";
import PhoneOrEmail from "src/components/PhoneOrEmail";
import { FaArrowLeftLong } from "react-icons/fa6";
import { errorCode } from "src/constants/common";
import CustomButton from "src/components/CustomButton";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { ArrowNarrowLeft, ChevronLeft } from "@untitled-ui/icons-react/build/cjs";
const ForgotPassword: FC<IForgotPasswordProps> = ({ setAuthData, authData, handleAuthAction }) => {
    const { t } = useTranslation();
    const { setFormatPhone } = usePhone();
    const [isLoading, setIsLoading] = useState(false);
    const inputType = authData?.email && !authData?.phone ? "phone" : authData?.phone && !authData?.email ? "email" : "both";

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
    } = useForm<IForgotPassword>({
        defaultValues: {
            emailPhone: "",
        },
    });

    const handleSave = (data: any) => {
        setIsLoading(true);
        if (data.emailPhone === "") {
            setError("emailPhone", {
                type: "manual",
                message: t("Enter phone number or email"),
            });
            setIsLoading(false);

            return;
        }
        const info = data.emailPhone;
        if (info.error) {
            setError("emailPhone", {
                type: "manual",
                message: info.error,
            });
            setIsLoading(false);

            return;
        }
        let payload = {};
        if (info.isEmail) {
            payload = {
                email: info.data,
            };
        } else {
            const mainNumber = `+${info.data.code}${info.data.number}`;
            payload = {
                phone: mainNumber,
                phone_country_code: info.data.country,
            };
        }
        let authDetail = { ...AuthDataInit, isEmail: info.isEmail };
        if (!info.isEmail) {
            const phoneInfo = info.data;
            const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
            const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
            authDetail = {
                ...authDetail,
                phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
            };
        } else {
            authDetail = {
                ...authDetail,
                email: info.data,
            };
        }
        axiosPost(API.USER.FORGOT_PASSWORD, payload)
            .then(() => {
                if (!info.isEmail) {
                    const phoneInfo = info.data;
                    const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
                    const fullFormatedPhoneNumber = `(+${phoneInfo.code}) ${formatedPhoneNumber}`;
                    authDetail = {
                        ...authDetail,
                        phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
                    };
                } else {
                    authDetail = {
                        ...authDetail,
                        email: info.data,
                    };
                }
                setAuthData(authDetail);
                handleAuthAction("forgotPasswordOtp");
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError("emailPhone", {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                    } else {
                        Object.keys(response).forEach((field) => {
                            setError("emailPhone", {
                                type: "manual",
                                message: response.message,
                            });
                        });
                    }
                }
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div className="content font-primary model-inner-wrapper">
            <h5 className="login-model-title">{t("Forgot Password?")}</h5>
            <p className="login-model-description ">{t("forgot_password_description")}</p>
            <div className="mt-[42px]">
                <form onSubmit={handleSubmit(handleSave)}>
                    <label htmlFor="phone" className="text-sm text-txtAppointmentColor flex leading-[18.2px] font-normal text-[14px] -tracking-[0.02rem] mb-1.5">
                        {t("Enter phone number or email")}
                        <span className="asterisk">*</span>
                    </label>
                    <Controller
                        name="emailPhone"
                        control={control}
                        render={({ field: { onChange } }: any) => (
                            <div className="flex flex-col ">
                                <PhoneOrEmail onNumberChange={onChange} errors={errors.emailPhone} inputType={inputType} />
                            </div>
                        )}
                    />
                    <div className="w-full flex justify-center"></div>
                    <div className="mt-[52px] w-full bottom-0 flex flex-col">
                        <CustomButton
                            type="button"
                            icon={<ChevronLeft width={20} />}
                            className="!border-0 ring-0 !p-0 !w-[32px] !h-[32px] absolute -left-[11px] lg:left-[12px] -top-[18px] lg:top-[12px] hover:!bg-gray-100 !rounded-lg  !bg-transparent text-center text-textGrey hover:!text-txtcolor"
                            shadow={false}
                            onClick={() => handleAuthAction("login")}
                        ></CustomButton>
                        <CustomButton primary type="submit" className="w-full !h-[48px]" isLoading={isLoading} disabled={isLoading}>
                            <span>{t("Continue")}</span>
                        </CustomButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;
