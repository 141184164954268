import React, { FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { Stripe, StripeElements, TokenResult } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { errorCode } from "src/constants/common";
import InputWithLabel from "src/components/InputWithLabel";
import { getBookingInfo, setIsCardLoading, setSubmitTriggered } from "../Booking.slice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";

interface IFormData {
    cardnumber: string;
    date: string;
    cvv: string;
    holdername: string;
}
interface ICardTabComponent {
    loadStripeCardList: () => Promise<void>;
}

const CardTabComponent: FC<ICardTabComponent> = ({ loadStripeCardList }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const bookingInfo = useAppSelector(getBookingInfo);
    const stripe: Stripe | null = useStripe();
    const elements: StripeElements | null = useElements();
    const [isCreateCardLoading, setIsCreateCardLoading] = useState(false);

    useEffect(() => {
        if (bookingInfo.submitTriggered && !bookingInfo.isCardAdd) {
            handleSubmit(onSubmit)();
            dispatch(setSubmitTriggered(false));
        }
    }, [bookingInfo.submitTriggered, bookingInfo.isCardAdd]);

    const [cardError, setCardError] = useState<{
        cardNumber?: string;
        cardExpiry?: string;
        cardCvc?: string;
    }>({});

    const {
        handleSubmit,
        control,
        setError,
        formState: { errors, isValid },
    } = useForm<IFormData>({
        defaultValues: {
            cardnumber: "",
            date: "",
            cvv: "",
            holdername: "",
        },
    });

    const onSubmit = async (data: IFormData) => {
        dispatch(setIsCardLoading(true));
        setIsCreateCardLoading(true);
        const cardElement = elements?.getElement(CardNumberElement);

        if (stripe && cardElement) {
            const { error, token }: TokenResult = await stripe.createToken(cardElement);
            if (error) {
                const errCode = error.code as string;
                const parts = errCode.split("_");

                if (parts[1] === "number") {
                    await setCardError((prev) => ({
                        ...prev,
                        cardNumber: error?.message,
                    }));
                }
                if (parts[1] === "expiry") {
                    await setCardError((prev) => ({
                        ...prev,
                        cardExpiry: error?.message,
                    }));
                }
                if (parts[1] === "cvc") {
                    await setCardError((prev) => ({
                        ...prev,
                        cardCvc: error?.message,
                    }));
                }
            }

            if (!data.holdername || data.holdername === "") {
                setError("holdername", {
                    type: "manual",
                    message: t("This field is required"),
                });
            }

            if (isValid && !error) {
                handleCreateCard(token?.id);
            } else {
                dispatch(setIsCardLoading(false));
                setIsCreateCardLoading(false);
            }
        }
    };

    const handleCreateCard = async (tokenId: string) => {
        try {
            setIsCreateCardLoading(true);
            dispatch(setIsCardLoading(true));
            const response = await axiosPost(API.STRIPE.CARD_ADD, {
                token: tokenId,
            });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                // handleClose(true);
                loadStripeCardList();
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message);
        } finally {
            dispatch(setIsCardLoading(false));
            setIsCreateCardLoading(false);
        }
    };

    return (
        // <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full">
        <div className="flex-1">
            <Controller
                name="holdername"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <div className="w-full mb-5">
                        <label htmlFor="cardnumber" className="text-txtAppointmentColor text-[14px] font-normal leading-[20px] -tracking-[0.03rem] mb-1.5 block">
                            Name on Card
                        </label>
                        <InputWithLabel
                            // label={t("Cardholder Name")}
                            placeholder={t("Enter card holder name")}
                            handleChange={(e) => onChange(e.target.value)}
                            value={value}
                            name="holdername"
                            inputClass={`!card-element !h-[44px] !py-[10px] !text-sm ${errors.holdername && "is-invalid border-solid"}`}
                        />
                        {errors.holdername && <p className="text-red-500 text-xs mt-[6px]">{errors.holdername && errors.holdername.message}</p>}
                    </div>
                )}
            />

            <div className="grid grid-cols-2 gap-4 w-full tracking-[0.03rem]">
                <Controller
                    name="cardnumber"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <div className="w-full mb-5">
                            <label htmlFor="cardnumber" className="text-txtAppointmentColor text-[14px] font-normal leading-[20px] -tracking-[0.03rem] mb-1.5 block">
                                Card number
                            </label>
                            <CardNumberElement
                                id="number-123"
                                className={`card-element !h-[44px]flex !pt-[14px] !text-base ${(errors.cardnumber || cardError.cardNumber) && "is-invalid"}`}
                                options={{
                                    style: {
                                        base: {
                                            color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                        },
                                    },
                                }}
                                onChange={(e) => {
                                    onChange(e.error ? "" : e.complete);
                                    setCardError((prev) => ({
                                        ...prev,
                                        cardNumber: e.complete ? "" : e.error?.message,
                                    }));
                                }}
                            />
                            {/* <CardNumberElement
                            options={{
                                style: {
                                    base: {
                                        lineHeight: "35px",
                                        color: cardNumberColor,
                                        textAlign: "left",
                                    },
                                },
                            }}
                            id="number-123"
                            className={`ba_form_control form_control_payment ${(errors.cardnumber || cardError.cardNumber) && "is-invalid border-solid text-secondaryTxt"}`}
                            onChange={(e) => {
                                onChange(e.error ? "" : e.complete);
                                setCardError((prev) => ({
                                    ...prev,
                                    cardNumber: e.complete ? "" : e.error?.message,
                                }));
                            }}
                        /> */}
                            {(errors.cardnumber || cardError.cardNumber) && (
                                <p className="text-red-500 mt-[6px] text-xs tracking-[0.03rem]">
                                    {(errors?.cardnumber || cardError.cardNumber) && (errors?.cardnumber?.message || cardError.cardNumber)}
                                </p>
                            )}
                        </div>
                    )}
                />
                <div className="grid grid-cols-2 gap-4 w-full">
                    <Controller
                        name="date"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <div className="w-full">
                                <label htmlFor="date" className="text-txtAppointmentColor text-[14px] font-normal leading-[20px] -tracking-[0.03rem] mb-1.5 block">
                                    Date
                                </label>

                                {/* <CardExpiryElement
                                    options={{
                                        style: {
                                            base: {
                                                lineHeight: "35px",
                                            },
                                        },
                                    }}
                                    className={`ba_form_control form_control_payment ${(errors.date || cardError.cardExpiry) && "is-invalid border-solid"}`}
                                    onChange={(e) => {
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev) => ({
                                            ...prev,
                                            cardExpiry: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                /> */}
                                <CardExpiryElement
                                    className={`card-element !h-[44px] pt-[13px] !text-base ${(errors.date || cardError.cardExpiry) && "is-invalid"}`}
                                    options={{
                                        style: {
                                            base: {
                                                color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                            },
                                        },
                                    }}
                                    onChange={(e) => {
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev) => ({
                                            ...prev,
                                            cardExpiry: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                />

                                {(errors.date || cardError.cardExpiry) && (
                                    <p className="text-red-500 text-xs mt-[6px]">{(errors?.date || cardError.cardExpiry) && (errors?.date?.message || cardError.cardExpiry)}</p>
                                )}
                            </div>
                        )}
                    />
                    <Controller
                        name="cvv"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <div className="w-full mb-5">
                                <label htmlFor="cvv" className="text-txtAppointmentColor text-[14px] font-normal leading-[20px] -tracking-[0.03rem] mb-1.5 block">
                                    CVC
                                </label>
                                {/* <CardCvcElement
                                    options={{
                                        style: {
                                            base: {
                                                lineHeight: "35px",
                                            },
                                        },
                                    }}
                                    className={`ba_form_control form_control_payment ${(errors.cvv || cardError.cardCvc) && "is-invalid border-solid"}`}
                                    onChange={(e) => {
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev) => ({
                                            ...prev,
                                            cardCvc: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                /> */}
                                <CardCvcElement
                                    className={`card-element border !h-[44px] pt-[13px] !text-base tracking-[0.03rem]  ${(errors.cvv || cardError.cardCvc) && "is-invalid"}`}
                                    options={{
                                        style: {
                                            base: {
                                                color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                            },
                                        },
                                    }}
                                    onChange={(e) => {
                                        onChange(e.error ? "" : e.complete);
                                        setCardError((prev) => ({
                                            ...prev,
                                            cardCvc: e.complete ? "" : e.error?.message,
                                        }));
                                    }}
                                />

                                {(errors.cvv || cardError.cardCvc) && (
                                    <p className="text-red-500 mt-[6px] text-xs tracking-[0.03rem]">{(errors?.cvv || cardError.cardCvc) && (errors?.cvv?.message || cardError.cardCvc)}</p>
                                )}
                            </div>
                        )}
                    />
                </div>
            </div>
        </div>
        // <div className="flex gap-3 justify-end bg-contentBackground pb-0 w-full">
        //     <CustomButton primary isLoading={isCreateCardLoading} disabled={isCreateCardLoading} className={`!max-w-max px-4`} type="submit">
        //         Add Card
        //     </CustomButton>
        // </div>
        // </form>
    );
};

export default CardTabComponent;
