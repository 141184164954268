import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useTranslation } from "react-i18next";
import { userLogout } from "../../redux/reducers/Login.slice";
import { logout } from "../../utils/global-functions";
import { toast } from "react-toastify";
import Landing from "./Landing";
import Notification from "./Notification";
import AccountSetting from "./AccountSetting";
import PaymentMethod from "./PaymentMethod";
import PopupModal from "src/components/PopupModal";
import NotificationSetting from "./NotificationSetting";
import BookingList from "./Booking/BookingList";
import CustomButton from "src/components/CustomButton";
import { initAction } from "./Profile.interface";
import AddNewCardTabComponent from "../BookAppointment/FinalizeBooking/AddNewCardTabComponent";

const Profile = ({ isOpen, handleClose }: any) => {
    const { t } = useTranslation();
    const authUserState = useAppSelector((data) => data.AuthUser);
    const dispatch = useAppDispatch();
    const [action, setAction] = useState(initAction);
    const uiState = useAppSelector((data) => data.UiStates);

    const handleAction = (type: string) => () => {
        const types = {
            [type]: true,
            landing: type !== "landing" && type !== "logout" ? false : true,
        };
        setAction((old) => ({ ...old, ...types }));
    };

    const handleConfirmModal = (isConfirm: boolean) => async () => {
        setAction(initAction);
        if (isConfirm) {
            if (action.logout) {
                const result = await dispatch(userLogout({}));

                if (result.type === userLogout.fulfilled.toString()) {
                    logout();
                    setAction(initAction);
                }

                if (result.type === userLogout.rejected.toString()) {
                    const response = result.payload.data;
                    toast.error(response.message);
                }
            }
        }
    };

    const handleActionClose = () => {
        setAction(initAction);
    };

    const handleSidebarClose = () => {
        setAction(initAction);
        if (action.landing) {
            handleClose();
        }
    };

    const handleBack = () => {
        setAction(initAction);
    };

    return (
        <>
            <Sidebar
                isOpen={isOpen}
                handleClose={handleSidebarClose}
                size="w-[460px]"
                customClass={`${uiState.isMobile ? "w-full h-[80%]" : "w-[460px]"}`}
                position={uiState.isMobile ? "bottom" : "right"}
                isBack={!action.landing}
                isBottomView={uiState.isMobile}
                handleBack={handleBack}
            >
                {isOpen && (
                    <>
                        {action.landing && <Landing handleAction={handleAction} handleClose={handleSidebarClose} />}
                        {action.notification && <Notification />}
                        {action.notification_setting && <NotificationSetting handleClose={handleSidebarClose} />}
                        {action.account_setting && <AccountSetting handleClose={handleSidebarClose} />}
                        {action.payment_method && <PaymentMethod />}
                        {action.appointment_history && <BookingList handleClose={handleSidebarClose} />}
                        {action.create_payment_method && <AddNewCardTabComponent closeAddNewCardTab={handleSidebarClose} />}
                    </>
                )}
            </Sidebar>
            {action.logout && (
                <PopupModal position="center" onClose={handleActionClose} size="max-w-[95%] min-w-[95%] sm:min-w-[480px] mt-[76px] mx-auto outline-custom">
                    <div className="content font-primary model-inner-wrapper px-5 lg:px-0 py-[50px]">
                        <h1 className="login-model-title">{t("Logout")}</h1>
                        <p className="login-model-subtitle">{t("Are you sure you want to logout from your account?")}</p>
                        <div className="flex mt-[42px] flex-col w-full gap-4">
                            <CustomButton className="!h-[48px]" secondary onClick={handleActionClose} disabled={authUserState.loading}>
                                <span> {t("Not now")}</span>
                            </CustomButton>
                            <CustomButton className="!h-[48px]" primary onClick={handleConfirmModal(true)} disabled={authUserState.loading} isLoading={authUserState.loading}>
                                {t("Yes, I am sure")}
                            </CustomButton>
                        </div>
                    </div>
                </PopupModal>
            )}
        </>
    );
};

export default Profile;
