import { SlashCircle01 } from "@untitled-ui/icons-react/build/cjs";
import { format } from "date-fns";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiCalendarEdit } from "react-icons/bi";
import { FiEdit3 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IAppointmentBooking, IAppointmentBookingServices } from "src/Interface/booked_appointment.interface";
import BookedBasketCalculation from "src/app/BookAppointment/AppointmentPreview/BasketCalculation/BookedBasketCalculation";
import {
    setBookingInitialState,
    setIsModifyingAppointment,
    setIsShowFinalizeBooking,
    setModifyingAppointmentId,
    setSelectedBookingDate,
    setSelectedBookingTime,
    setPaymentType,
    setPaymentOption,
    setOldPaymentOption,
    setSelectedProducts,
    setSelectedServices,
    setSelectedShopLocation,
    setSelectedSpecialist,
    setSelectedPromocode,
} from "src/app/BookAppointment/Booking.slice";
import DeletePopupModal from "src/components/DeletePopupModal/DeletePopupModal";
import { API } from "src/constants/api";
import { PATH } from "src/constants/path";
import { ROUTES } from "src/constants/routes";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { currentShop, getAllShopProducts } from "src/redux/reducers/common/Common.slice";
import { DemoSvg, Facebook1SVG, GoogleSVG, Instagram1SVG, PlaceholderImgPNG } from "src/theme/Images";
import { axiosPatch } from "src/utils/requestClient";

interface IProps {
    booking: IAppointmentBooking;
    handleClose: () => void;
    setIsRefresh: (status: boolean) => void;
}

const BookingView = ({ booking, handleClose, setIsRefresh }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const [isShowCancelAppointmentConfirmationModal, setIsShowCancelAppointmentConfirmationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleModifyAppointment = async () => {
        dispatch(setBookingInitialState());
        handleClose();

        dispatch(setIsModifyingAppointment(true));
        dispatch(setModifyingAppointmentId(booking.id));

        dispatch(setIsShowFinalizeBooking(false));
        dispatch(setSelectedShopLocation(booking.location));

        dispatch(setSelectedSpecialist(booking.staff));
        dispatch(setSelectedBookingDate(booking.booking_date));
        dispatch(setSelectedBookingTime(format(new Date(`${booking.booking_date} ${booking.booking_start_time}`), "HH:mm")));
        dispatch(setPaymentType(booking?.payment_type));
        dispatch(setPaymentOption(booking?.payment_option));
        dispatch(setOldPaymentOption(booking?.payment_option));

        if (booking.shop_promotion) {
            dispatch(setSelectedPromocode(booking?.shop_promotion));
        }

        const selectedServices: any = [];
        booking.booking_services.forEach((booking_service: IAppointmentBookingServices) => {
            const { service, quantity } = booking_service;
            for (let index = 0; index < quantity; index++) {
                selectedServices.push({ ...service, quantity: 1 });
            }
        });
        dispatch(setSelectedServices(selectedServices));

        const selectedProducts: any = [];
        booking?.booking_products?.forEach((booking_product) => {
            const { product, quantity, product_variant: variant, product_inventory: inventory } = booking_product;
            for (let index = 0; index < quantity; index++) {
                selectedProducts.push({ ...product, selectedVariant: { ...variant, inventory } });
            }
        });
        dispatch(setSelectedProducts(selectedProducts));

        const payload = {
            params: {
                shop_id: shop.id,
                location_id: booking.location.id,
            },
            data: {
                booking_id: booking.id,
            },
        };
        await dispatch(getAllShopProducts(payload));
        navigate(PATH.APPOINTEMENT);
    };

    const handleCancelAppointment = () => {
        setIsLoading(true);
        const payload = {
            status: "request_canceled",
        };
        const params = {
            shop_id: booking.shop?.id,
            id: booking.id,
        };
        axiosPatch(API.BOOKING.UPDATE_STATUS, payload, params)
            .then((response) => {
                toast.success(response.data.message);
                setIsRefresh(true);
                handleClose();
            })
            .catch((error: any) => {
                toast.error(error?.message);
            })
            .finally(() => {
                setIsLoading(false);
                setIsShowCancelAppointmentConfirmationModal(false);
            });
    };

    const handleModalClose = (status: boolean) => () => {
        setIsShowCancelAppointmentConfirmationModal(status);
    };

    return (
        <div className="relative w-[100%] flex flex-col  h-[calc(100%-20px)] font-primary overflow-hidden">
            <div className="flex flex-col mb-5">
                <h3 className="text-[20px] mb-1.5 leading-[28px] font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">Appointment #{booking.receipt_code}</h3>
                {/* <span className="text-base text-[#D88218] font-medium">Pending</span> */}
                {booking.status === "confirmed" && <span className="text-success text-base font-medium -tracking-[0.03rem]">{t("Confirmed")}</span>}
                {booking.status === "pending" && <span className="text-pending text-base font-medium -tracking-[0.03rem]">{t("Pending")}</span>}
                {booking.status === "declined" && <span className="text-danger text-base font-medium -tracking-[0.03rem]">{t("Declined")}</span>}
            </div>
            <div className="w-[100%] h-full scrollbar-hide">
                <img src={booking.location.location_image_url} alt="" onError={(event) => (event.currentTarget.src = DemoSvg)} className=" w-full h-[165px] object-cover rounded-secondary" />
                <div className="pb-4">
                    <div className="w-full  pt-4 p-2.5  bg-bgContent rounded-secondary  mt-5 ">
                        <h1 className="leading-[28px] text-lg lg:text-[12.8px] lg:leading-4 xl:text-base xl:leading-[22.4px] 2xl:text-lg 2xl:leading-[25.2px] font-medium  text-txtAppointmentColor -tracking-[0.03rem]">
                            {booking?.location?.name}
                        </h1>
                        <p className="mt-1 text-secondaryTxt text-[11px] font-normal lg:text-xs lg:leading-5 2xl:text-sm 2xl:leading-[18.2px]  md:text-sm md:leading-[140%] text-textGrey -tracking-[0.03rem]">
                            {`${booking?.location?.street}, ${booking?.location?.city}, ${booking?.location?.country} ${booking?.location?.postal_code}`}
                        </p>
                        {/* <hr className="text-lightgray my-3" /> */}
                    </div>
                </div>
                <div className="h-[calc(100vh-458px)] lg:h-[calc(100vh-388px)] overflow-auto pb-[135px] pt-3 scrollbar-hide">
                    {/* <p className="mt-5 text-lg leading-[25.2px] lg:text-sm lg:leading-[18px] xl:text-base xl:leading-[22px] 2xl:text-lg 2xl:leading-[25.2px] -tracking-[0.01em] ">Order Summary</p> */}

                    <div className="flex flex-col gap-3">
                        <h1 className="text-base leading-[22.4px] font-medium text-textGrey -tracking-[0.03rem] ">Specialist</h1>
                        <div className="flex gap-2 items-center ">
                            <div className="img">
                                <img
                                    src={booking.staff.profile_image_url}
                                    className="w-[46px] h-[46px] rounded-primary object-cover"
                                    alt=""
                                    onError={(event) => (event.currentTarget.src = PlaceholderImgPNG)}
                                />
                            </div>
                            <div className="flex flex-col">
                                <h2 className="text-txtAppointmentColor text-sm leading-[18.2px]  font-medium  -tracking-[0.02rem]">{booking?.staff?.full_name}</h2>
                                <p className="text-xs leading-[18px] mt-1   text-textGrey -tracking-[0.02rem]">{booking.staff?.staff_role?.name}</p>
                            </div>
                        </div>
                    </div>

                    {/* Date And Time   */}
                    <div className=" mt-4">
                        <div className="flex items-center ">
                            <div className="flex flex-col gap-3">
                                <h2 className="text-base leading-[22.4px] font-medium text-textGrey -tracking-[0.03rem]">Date and Time</h2>
                                <div className="flex gap-3 items-center">
                                    <div className="bg-bgContent w-[25px] h-[25px] flex justify-center items-center rounded-primary text-[14px] text-txtAppointmentColor">
                                        <BiCalendarEdit className="w-5 h-5 rounded-primary" />
                                    </div>
                                    <p className="text-txtAppointmentColor text-sm leading-[18.2px]  font-medium  -tracking-[0.02rem]">
                                        {/* {selectedDate && selectedTimeSlot
                                                ? `${moment(selectedDate).format("DD MMM yyyy")}
                                    at
                                    ${format(new Date(`2000-01-01T${selectedTimeSlot}`), "hh:mm a")}`
                                                : `${moment(bookingState.selectedBookingDate).format("DD MMM yyyy")}
                                        at
                                        ${format(new Date(`2000-01-01T${bookingState.selectedBookingTime}`), "hh:mm a")}`} */}

                                        {moment(booking.booking_date).format("dddd, DD MMM YYYY • ")}
                                        {format(new Date(`${booking.booking_date} ${booking.booking_start_time}`), "hh:mm-")}
                                        {format(new Date(`${booking.booking_date} ${booking.booking_end_time}`), "hh:mm a")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Service  */}
                    <div className="mt-4 flex flex-col gap-3">
                        <h2 className="text-base leading-[22.4px] lg:text-xs xl:text-sm  2xl:text-base 2xl:leading-[22.4px] font-medium   text-textGrey tracking-[0.03rem]">Service</h2>
                        {booking.booking_services.map((bookingService, index) => (
                            <div className="flex justify-between items-center" key={bookingService.service.name}>
                                <div className="flex gap-3 items-center">
                                    <div className="bg-bgContent w-[25px] h-[25px] flex justify-center items-center rounded-primary text-[14px] text-txtAppointmentColor">
                                        <div className="">{bookingService.quantity}</div>
                                    </div>
                                    <p className="text-txtAppointmentColor text-sm leading-[18.2px] lg:text-[10px] lg:leading-3  xl:text-xs 2xl:text-sm 2xl:leading-[18.2px] font-medium  truncate max-w-[75px] lg:max-w-[148px] pt-[3px] tracking-[0.05rem]">
                                        {bookingService.service.name}
                                    </p>
                                </div>
                                <p className="text-txtAppointmentColor text-sm leading-[18.2px]  lg:text-[10px] lg:leading-3 xl:text-xs 2xl:text-sm 2xl:leading-[18.2px] font-medium tracking-[0.04rem]">
                                    ${bookingService.price}
                                </p>
                            </div>
                        ))}
                    </div>

                    {/* Products */}
                    {booking.booking_products.length > 0 && (
                        <div className="mt-4 flex flex-col gap-3">
                            <h2 className="text-base leading-[22.4px] lg:text-xs xl:text-sm  2xl:text-base 2xl:leading-[22.4px] font-medium   text-textGrey tracking-[0.03rem]">Product</h2>
                            {booking.booking_products.map((product, index) => (
                                <div className="flex justify-between items-center" key={`${product.product.name}-${index}`}>
                                    <div className="flex gap-3 items-center">
                                        <div className="bg-bgContent w-[25px] h-[25px] flex justify-center items-center rounded-primary text-[14px] text-txtAppointmentColor">
                                            <div className="">{product.quantity}</div>
                                        </div>
                                        <p className="text-txtAppointmentColor text-sm leading-[18.2px] lg:text-[10px] lg:leading-3  xl:text-xs 2xl:text-sm 2xl:leading-[18.2px] font-medium  truncate max-w-[75px] lg:max-w-[148px] pt-[3px] tracking-[0.03rem]">
                                            {product.product.name}
                                        </p>
                                        <span className="max-w lg:ml-2 ml-[2px] rounded-md text-[12px] lg:text-sm font-normal border h-[19px] px-1 lg:px-2.5 lg:py-1 items-center justify-center flex text-txtAppointmentColor border-headerBorderColor gap-[3px]">
                                            <span> {product.product_variant.size} </span>
                                            <span> {product.product.unit_of_measure}</span>
                                        </span>
                                    </div>
                                    <p className="text-txtAppointmentColor text-sm leading-[18.2px]  lg:text-[10px] lg:leading-3 xl:text-xs 2xl:text-sm 2xl:leading-[18.2px] font-medium  tracking-[0.04rem]">
                                        ${product.product_inventory.price}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                    <BookedBasketCalculation staticInfo={booking} />
                </div>
                <div className="mt-auto absolute bottom-0  w-full flex flex-col gap-3 bg-contentBackground pt-2">
                    <div className="flex flex-row gap-2 w-full">
                        {(booking.status === "confirmed" || booking.status === "pending") && (
                            <button
                                className={`flex justify-center w-[50%] items-center border h-[42px] border-borderAppointmentColor px-4 transition-all ease-in-out delay-100 font-semibold text-base bg-transparent text-txtAppointmentColor rounded-secondary
                        }`}
                                onClick={handleModifyAppointment}
                            >
                                <FiEdit3 size={20} className="mr-3 max-lg:hidden" />
                                <span className="font-medium text-sm lg:text-base bg-transparent text-txtAppointmentColor tracking-[0.02rem]">Edit appointment</span>
                            </button>
                        )}
                        {/* {booking?.status === "completed" && (
                            <button
                                className={`flex justify-center w-[50%] items-center border h-[42px] border-primaryAppointment px-4  transition-all ease-in-out delay-100 font-semibold text-base bg-transparent text-primaryAppointment   rounded-lg
                        }`}
                            >
                                <span className="font-medium text-base bg-transparent text-primaryAppointment pt-1"> Give us a Review</span>
                            </button>
                        )} */}
                        {(booking.status === "confirmed" || booking.status === "pending") && (
                            <button
                                className={`flex justify-center w-[50%] items-center border h-[42px] border-borderAppointmentColor px-4  transition-all ease-in-out delay-100 font-semibold text-base bg-transparent text-primaryAppointment   rounded-secondary
                         }`}
                                onClick={() => setIsShowCancelAppointmentConfirmationModal(true)}
                            >
                                <span className="font-medium text-sm lg:text-base bg-transparent text-primaryAppointment  tracking-[0.02rem]"> {t("Cancel")}</span>
                            </button>
                        )}
                    </div>
                    <div className="flex flex-row gap-2 mx-auto">
                        <button
                            className={` flex w-[108px] justify-center items-center border h-[42px] border-[#EDF1FD]  transition-all ease-in-out delay-100 font-semibold text-base bg-contentBackground text-txtAppointmentColor  rounded-secondary
                        }`}
                        >
                            <GoogleSVG className="mr-2 w-5 h-5" />
                            <span className="font-medium text-xs bg-transparent text-txtAppointmentColor  tracking-[0.03rem]"> Google</span>
                        </button>
                        <button
                            className={`flex w-[108px] justify-center items-center border h-[42px] border-[#EDF1FD]  transition-all ease-in-out delay-100 font-semibold text-base  bg-contentBackground text-txtAppointmentColor  rounded-secondary
                        }`}
                        >
                            <Facebook1SVG className="mr-2 w-5 h-5" />
                            <span className="font-medium text-xs bg-transparent text-txtAppointmentColor  tracking-[0.03rem]">Facebook</span>
                        </button>
                        <button
                            className={`flex w-[108px] justify-center items-center border h-[42px] border-[#EDF1FD]   transition-all ease-in-out delay-100 font-semibold text-base  bg-contentBackground text-txtAppointmentColor  rounded-secondary
                        }`}
                        >
                            <img src={Instagram1SVG} className="mr-2 w-5 h-5" alt="" />
                            <span className="font-medium text-xs bg-transparent text-txtAppointmentColor  tracking-[0.03rem]"> Instagram</span>
                        </button>
                    </div>
                    {isShowCancelAppointmentConfirmationModal && (
                        <DeletePopupModal
                            primary
                            headerIcon={<SlashCircle01 className="w-6 h-6 text-gray-700" />}
                            confirmButtonText="Confirm"
                            onClose={handleModalClose}
                            size="w-[400px]"
                            title={t("Cancel appointment")}
                            description={t("Are you sure you want to cancel this appointment?")}
                            isLoading={isLoading}
                            onConfirm={handleCancelAppointment}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default BookingView;
