import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StepProgressBar from "src/components/Stepper";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { EBookingTabState } from "./bookAppointment.interface";
import { getBookingInfo, setProductCategory } from "./Booking.slice";
import { currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import { FilterLines, Check } from "@untitled-ui/icons-react/build/cjs";
import { axiosGet } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { errorCode } from "src/constants/common";
import { Dropdown } from "flowbite-react";
import { toast } from "react-toastify";

const Stepper: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const bookingInfo = useAppSelector(getBookingInfo);
    const [productCategoryLists, setProductCategoryLists] = useState<any[]>([]);

    const getProductCategories = async () => {
        try {
            const payloadObj = {
                shop_id: shop.id,
                shop_location_id: bookingInfo.selectedShopLocation?.id,
            };
            const response = await axiosGet(API.PRODUCT.CATEGORY, { shop_location_id: payloadObj.shop_location_id }, { shop_id: payloadObj.shop_id });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setProductCategoryLists(response.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {
            toast.error(err?.message ?? "Something went wrong, Please try again");
        }
    };

    useEffect(() => {
        const initialLoad = async () => {
            await getProductCategories();
        };
        if (bookingInfo.selectedShopLocation) {
            initialLoad();
        }
    }, []);

    const getSteps = () => {
        console.log("is_verified_stripe_account", shop.admin.is_verified_stripe_account);
        return shop.admin.is_verified_stripe_account || (user && user.total_loyalty_amount >= bookingInfo.bookingTotal) ? 4 : 3;
        // if (productList.length > 0) {
        //     return shop.admin.is_verified_stripe_account || (user && user.total_loyalty_amount >= bookingInfo.bookingTotal) ? 4 : 3;
        // } else {
        //     return shop.admin.is_verified_stripe_account || (user && user.total_loyalty_amount >= bookingInfo.bookingTotal) ? 4 : 3;
        // }
    };

    const getCompletedStepsCount = () =>
        [bookingInfo.selectedSpecialist, bookingInfo.selectedServices?.length, bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime ? true : false].filter(Boolean).length;

    const stepContent: Record<EBookingTabState, { title?: string; subtitle?: React.ReactNode; step: number } | undefined> = {
        [EBookingTabState.TAB_MENU]: {
            step: getCompletedStepsCount(),
            title: t("Create an appointment"),
            subtitle: (
                <>
                    {t("Book your next appointment at")} <span className="text-primaryAppointment">{shop.business_name}</span>
                </>
            ),
        },
        [EBookingTabState.PRODUCT_BOOKING]: {
            step: 4,
            title: t("Add a product to your order"),
            subtitle: t("This is optional and this product can only be picked up in store"),
        },
        [EBookingTabState.FINALIZE_BOOKING]: {
            step: 5,
            title: t("Finalize Your Booking"),
            subtitle: t("Complete payment to complete the order"),
        },
        [EBookingTabState.SELECT_SPECIALIST]: undefined,
        [EBookingTabState.SELECT_DATE_TIME]: undefined,
        [EBookingTabState.SELECT_SERVICE]: undefined,
    };
    const renderStepComponent = () => {
        const content = stepContent[bookingInfo.step];
        return (
            <>
                {content && (
                    <div className="mb-4">
                        {bookingInfo.step === EBookingTabState.PRODUCT_BOOKING ? (
                            <>
                                <div className="flex items-center justify-between">
                                    <div className="flex flex-col justify-start">
                                        <h3 className="location-title-text">{content.title}</h3>
                                        <p className="location-sub-text">{content.subtitle}</p>
                                    </div>
                                    <div className="ml-4 relative">
                                        {" "}
                                        {/* Add margin to separate the dropdown from the text */}
                                        <Dropdown
                                            className="bg-dropdownBackgroundColor border-dropdownBackgroundColor border-0"
                                            theme={{
                                                arrowIcon: "ml-2 h-4 w-4",
                                                content:
                                                    "py-1 focus:outline-none bg-dropdownBackgroundColor !right-0 fixed z-50 rounded-secondary shadow-InputAndButton px-2.5 py-2 space-y-1 border border-borderColorSecondary",
                                            }}
                                            label=""
                                            placement="bottom-start"
                                            dismissOnClick={true}
                                            inline
                                            renderTrigger={() => (
                                                <button
                                                    type="button"
                                                    className="min-w-[40px] h-[40px] border border-borderColorSecondary lg:border-borderColorSecondary rounded-md flex items-center justify-center lg:mt-0 relative"
                                                >
                                                    <FilterLines color="#8C9CBB" className="" />
                                                </button>
                                            )}
                                        >
                                            {productCategoryLists.map((categoryList) => (
                                                <Dropdown.Item
                                                    theme={{
                                                        container: "right-0",
                                                        icon: "text-textGrey mr-3 h-4 w-4 bg-black ",
                                                        base: `${
                                                            bookingInfo?.productCategoryFilterValue?.value === categoryList.id && "bg-[#F8F8F8] rounded-[8px] text-txtcolor focus:outline-none"
                                                        }  flex w-[250px] md:w-[210px]  right-0 -tracking-[0.02rem] cursor-pointer items-center justify-start px-2.5  py-2 text-sm font-normal text-txtcolor  active:text-txtcolor  active:bg-[#F8F8F8] active:rounded-[8px] hover:rounded-[8px] focus:rounded-[8px] hover:bg-[#F8F8F8] focus:bg-[#F8F8F8] focus:outline-none focus:ring-0`,
                                                    }}
                                                    key={categoryList.id}
                                                    onClick={() => {
                                                        bookingInfo?.productCategoryFilterValue?.value === categoryList.id
                                                            ? dispatch(setProductCategory(null))
                                                            : dispatch(setProductCategory({ label: categoryList.name, value: categoryList.id }));
                                                    }}
                                                >
                                                    <div className="flex items-center justify-between gap-2 w-full">
                                                        {categoryList.name}
                                                        {bookingInfo?.productCategoryFilterValue?.value === categoryList.id && <Check className="text-txtcolor h-4 w-4 mr-1.5" />}
                                                    </div>
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                        {bookingInfo?.productCategoryFilterValue && (
                                            <div className="w-[13px] h-[13px] bg-primary rounded-full absolute -top-1 -right-1 border-[3px]  border-contentBackground "></div>
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            content.title && (
                                <>
                                    <h3 className="location-title-text">{content.title}</h3>
                                    <p className="location-sub-text">{content.subtitle}</p>
                                </>
                            )
                        )}
                    </div>
                )}
                <div className="w-[100%] self-center">
                    <StepProgressBar steps={getSteps()} currentStep={content && bookingInfo.step !== EBookingTabState.PRODUCT_BOOKING ? content.step : getCompletedStepsCount()} />
                </div>
            </>
        );
    };

    return <>{renderStepComponent()}</>;
};

export default Stepper;
