import React, { useEffect, useRef, useState } from "react";
import { IInitAction, initAction, initData } from "../Auth.interface";
import SocialProfile from "./SocialProfile";
import VerifyOtp from "./VerifyOtp";
import { useAppSelector } from "src/redux/hooks";
import { currentUser } from "src/redux/reducers/common/Common.slice";
import RegularProfile from "./RegularProfile";
import PopupModal from "src/components/PopupModal";
import Sidebar from "src/components/Sidebar";

interface IProps {
    handleClose: () => void;
}

const CompleteProfile = ({ handleClose }: IProps) => {
    const popUpRef = useRef(null);
    const uiState = useAppSelector((data) => data.UiStates);
    const user = useAppSelector(currentUser);
    const [action, setAction] = useState(initAction);
    const [authData, setAuthData] = useState(initData);
    const [upload, setUpload] = useState(false);

    useEffect(() => {
        if (user) {
            const isAccountComplete = user.is_account_complete;
            if (!isAccountComplete) {
                const socialId = user.social_id;
                if (socialId) {
                    handleAction("socialProfile")();
                } else {
                    handleAction("regularProfile")();
                }
            }
        }
    }, [user]);

    const handleAction = (type: keyof IInitAction) => () => {
        setAction(() => {
            const resetActions = Object.keys(initAction).reduce((acc, key) => {
                acc[key as keyof IInitAction] = false;
                return acc;
            }, {} as IInitAction);
            return { ...resetActions, [type]: true };
        });
    };

    return (
        <>
            {uiState.isMobile ? (
                <Sidebar
                    ref={popUpRef}
                    isOpen={uiState.isMobile}
                    position="bottom"
                    showCloseIcon={false}
                    handleClose={handleClose}
                    customClass={`w-full rounded-t-[24px] ${"h-[80%]"}`}
                    size={""}
                    isLogout={!upload}
                >
                    <div className="flex flex-col w-full">
                        {action.regularProfile ? (
                            <RegularProfile handleClose={handleClose} upload={upload} setUpload={setUpload} />
                        ) : action.socialProfile ? (
                            <SocialProfile setAuthData={setAuthData} handleAction={handleAction} upload={upload} setUpload={setUpload} />
                        ) : (
                            action.otp && <VerifyOtp authData={authData} setAuthData={setAuthData} handleAction={handleAction} handleClose={handleClose} />
                        )}
                    </div>
                </Sidebar>
            ) : (
                <PopupModal
                    ref={popUpRef}
                    isLogout={!upload}
                    onClose={handleClose}
                    size="max-w-[95%] min-w-[360px] mt-[76px] mx-auto lg:w-[600px] font-primary outline-custom"
                    isCrossSign={false}
                    className="!pt-0 !pb-0"
                    dismissible={false}
                    isLogo
                >
                    <div className="w-full flex flex-col">
                        {action.regularProfile ? (
                            <RegularProfile handleClose={handleClose} upload={upload} setUpload={setUpload} />
                        ) : action.socialProfile ? (
                            <SocialProfile setAuthData={setAuthData} handleAction={handleAction} upload={upload} setUpload={setUpload} />
                        ) : (
                            action.otp && <VerifyOtp authData={authData} setAuthData={setAuthData} handleAction={handleAction} handleClose={handleClose} />
                        )}
                    </div>
                </PopupModal>
            )}
        </>
    );
};

export default CompleteProfile;
