// import React from "react";
import React, { memo, FC, forwardRef, useEffect } from "react";
import { Tooltip as FlowbiteTooltip } from "flowbite-react";
import { ITooltip } from "./Tooltip.interface";

const Tooltip = ({ title, description, titleClassname, position = "auto", descriptionClassname, children }: ITooltip) => (
    <FlowbiteTooltip
        content={
            <div>
                {title && <p className={`text-tooltipTextColor text-sm font-medium -tracking-[0.01rem] mb-1 ${titleClassname}`}>{title}</p>}
                {description && <p className={`text-tooltipTextColor font-normal text-xs -tracking-[0.01rem] ${descriptionClassname}`}>{description}</p>}
            </div>
        }
        trigger="hover"
        style={"light" as any}
        className="w-64 bg-tooltipBg !rounded-secondary tracking-[0.03rem] !py-3 !border-0"
        placement="auto"
    >
        {children}
    </FlowbiteTooltip>
);

export default Tooltip;
